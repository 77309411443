import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Image, Form, Button, DropdownButton } from 'react-bootstrap'
import EditText from './EditText'

function Records({ setting }) {
  const { closeRecord = () => {} } = setting
  const [records, setRecords] = useState([
    {
      name: '7月21日 下午2:00',
    },
    {
      name: '7月20日 下午2:00',
    },
  ])

  const handleInputChange = () => {}

  return (
    <div className="rightSidenav">
      <div className="sidenav-header">
        <Image src="/images/svgicons/records.svg" alt="" />
        版本紀錄
        <Button className="btn-text close-btn" onClick={() => closeRecord()}>
          <div
            className="svgIcon"
            style={{
              maskImage: `url('/images/svgicons/close.svg')`,
            }}
          >
            關閉
          </div>
        </Button>
      </div>
      <div className="scroll-sidebar">
        {records.map((record, index) => (
          <div
            className="record-item"
            key={`record${index}`}
            style={{ zIndex: records.length - index }}
          >
            <strong>{record.name}</strong>
            {index === 0 ? '目前版本' : ''}

            <div className="project-setting">
              <DropdownButton
                title={
                  <div className="btn-setting d-flex">
                    <div
                      className="svgIcon settingIcon"
                      style={{
                        maskImage: `url('/images/svgicons/setting.svg')`,
                      }}
                    >
                      設定
                    </div>
                  </div>
                }
              >
                <div className="project-setting-card toggle-wrap shadow-block">
                  <Form>
                    <ul className="toggle-wrap-nav">
                      <li>
                        <EditText
                          setting={{
                            title: record.name,
                            handleInputChange,
                            style: 'front',
                            defauleTitle: '為此版本命名',
                          }}
                        />
                      </li>
                      <li>
                        <Button
                          className="btn-text"
                          onClick={() => {
                            setRecords([
                              { name: `${record.name}的副本` },
                              ...records,
                            ])
                          }}
                        >
                          <div
                            className="svgIcon"
                            style={{
                              maskImage: `url('/images/svgicons/copy.svg')`,
                            }}
                          >
                            刪除
                          </div>
                          建立副本
                        </Button>
                      </li>
                    </ul>
                  </Form>
                </div>
              </DropdownButton>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

Records.propTypes = {
  setting: PropTypes.shape().isRequired,
}

export default Records
