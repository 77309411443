import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Row, Col, Form } from 'react-bootstrap'
import ParentSize from '@visx/responsive/lib/components/ParentSize'
import { Pie } from '@visx/shape'
import { Group } from '@visx/group'

const colors = ['#00FFBD', '#3F75ED']
const defaultMargin = { top: 20, right: 20, bottom: 20, left: 20 }

function CircleItem({ pieProps }) {
  const { width, height, margin = defaultMargin, number } = pieProps
  const [percent, setPercent] = useState(number)
  const [values, setValues] = useState([])

  const innerWidth = width - margin.left - margin.right
  const innerHeight = height - margin.top - margin.bottom
  const radius = Math.min(innerWidth, innerHeight) / 2
  const centerY = innerHeight / 2
  const centerX = innerWidth / 2
  const top = centerY + margin.top
  const left = centerX + margin.left
  const pieSortValues = (a, b) => {
    if (a > b) {
      return a - b
    }

    return b - a
  }

  useEffect(() => {
    setValues([
      { symbol: 'A', value: percent },
      { symbol: 'B', value: Math.floor(100 - percent) },
    ])
  }, [percent])

  return (
    <div className="circleItem">
      <div className="circleCenter">
        <Form.Control
          className=""
          value={percent}
          type="number"
          min={0}
          max={100}
          onChange={(e) => {
            setPercent(e.target.value)
          }}
        />
        %
      </div>
      <svg width={width} height={height}>
        <Group top={top} left={left}>
          <Pie
            data={values}
            pieValue={(data) => data.value}
            pieSortValues={pieSortValues}
            outerRadius={radius}
            innerRadius={radius - 50}
          >
            {(pie) =>
              pie.arcs.map((arc, index) => {
                const { letter } = arc.data
                const arcPath = pie.path(arc)

                return (
                  <g key={`arc-${letter}-${index}`}>
                    <path d={arcPath} fill={colors[index]} />
                  </g>
                )
              })
            }
          </Pie>
        </Group>
      </svg>
    </div>
  )
}

function Chat({ setting }) {
  const { value = 0 } = setting

  return (
    <div className="articleBlock text-start">
      <Row>
        <Col>
          <ParentSize>
            {({ width }) => (
              <CircleItem
                pieProps={{
                  width,
                  height: '500',
                  number: value,
                }}
              />
            )}
          </ParentSize>
        </Col>
      </Row>
    </div>
  )
}

Chat.propTypes = {
  setting: PropTypes.shape().isRequired,
}

CircleItem.propTypes = {
  pieProps: PropTypes.shape().isRequired,
}

export default Chat
