import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import { Editor } from '@tinymce/tinymce-react'

// https://www.tiny.cloud/docs/tinymce/latest/react-pm-bundle/
function TextArea({ setting }) {
  // const { value, itemIndex, textEdit = () => {} } = setting
  const { value, itemIndex } = setting
  // const { label, itemIndex, value, textEdit = () => {} } = setting
  const editorRef = useRef(null)

  const trashIcon = ''
  const defaultToolbar = `H1 H2 H3 | bold italic underline link | numlist bullist ${trashIcon}`

  return (
    <div className="textarea-group">
      <Editor
        tinymceScriptSrc="/tinymce/tinymce.min.js"
        onInit={(evt, editor) => {
          editorRef.current = editor
        }}
        initialValue={value}
        // value={value}
        init={{
          height: 500,
          menubar: false,
          inline: true,
          license_key: 'gpl',
          file_picker_callback: (callback, fileValue, meta) => {
            if (meta.filetype === 'file') {
              callback('https://www.google.com/logos/google.jpg', {
                text: 'My text',
              })
            }

            if (meta.filetype === 'image') {
              callback('https://www.google.com/logos/google.jpg', {
                alt: 'My alt text',
              })
            }
          },
          setup: (editor) => {
            editor.on('focus', () => {
              const editorHtml = editor.getElement()
              editorHtml.style.cursor = 'text'
              let parent = editor.getElement().parentElement
              while (parent) {
                if (
                  parent.classList.contains('column-item') ||
                  parent.classList.contains('has-drag')
                ) {
                  parent.classList.add('unfocus')
                }
                parent = parent.parentElement
              }
            })
            editor.on('blur', () => {
              const editorHtml = editor.getElement()
              editorHtml.style.cursor = ''
              let parent = editor.getElement().parentElement
              while (parent) {
                if (
                  parent.classList.contains('column-item') ||
                  parent.classList.contains('has-drag')
                ) {
                  parent.classList.remove('unfocus')
                }
                parent = parent.parentElement
              }
            })
          },
          plugins: [
            'advlist',
            'anchor',
            'autolink',
            'image',
            'link',
            'lists',
            'searchreplace',
            'table',
            'wordcount',
          ],
          toolbar: defaultToolbar,
        }}
        onEditorChange={(e) => {
          console.log(e, itemIndex)
          // textEdit(e, itemIndex)
          // textEdit(label, itemIndex, e)
        }}
      />
    </div>
  )
}

TextArea.propTypes = {
  setting: PropTypes.shape().isRequired,
}

export default TextArea
