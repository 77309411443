// import React, { useContext, useEffect, useState } from 'react'
import React from 'react'
import PropTypes from 'prop-types'
// import { DraftContext } from './ContextProvider'
import { SectionTitle, Card } from '../tools'
import { usePestelStore } from '../store'

function SuggestCard({ setting }) {
  const { module = [], boardStyle } = setting

  const { updatePestelInfo, removePestelInfo, addPestelInfo } = usePestelStore(
    (store) => ({
      updatePestelInfo: store.updatePestelInfo,
      removePestelInfo: store.removePestelInfo,
      addPestelInfo: store.addPestelInfo,
    })
  )
  // const { cardList, setCardList } = useContext(DraftContext)

  // undo and redo
  // const {
  //   cursor,
  //   setCursor,
  //   history,
  //   setHistory,
  //   recoverTarget,
  //   setRecoverTarget,
  // } = useContext(DraftContext)

  // useEffect(() => {
  //   setHistory([module])
  //   setCursor(-1)
  //   setRecoverTarget(-1)
  // }, [])

  // useEffect(() => {
  //   if (recoverTarget !== -1) {
  //     setCardList(history[recoverTarget])
  //   }
  // }, [recoverTarget])

  // useEffect(() => {
  //   setCardList(module)
  // }, [module])

  // useEffect(() => {
  //   if (recoverTarget >= 0) {
  //     setCursor(recoverTarget)
  //     setRecoverTarget(-1)
  //   } else {
  //     setHistory((prevState) => [...prevState.slice(0, cursor + 1), module])
  //     setCursor((prevState) => prevState + 1)
  //   }
  // }, [cardList])

  const getFieldByLabel = (label) => {
    switch (label) {
      case 0:
        return 'politic'
      case 1:
        return 'economic'
      case 2:
        return 'social'
      case 3:
        return 'technology'
      case 4:
        return 'environment'
      case 5:
        return 'law'
      default:
        throw new Error('Invalid label')
    }
  }
  const getPartialGenerate = async (id) => {
    let url = ''
    switch (id) {
      case 0:
        url = 'data/pestel/politic_partial_generation.json'
        break
      case 1:
        url = 'data/pestel/economic_partial_generation.json'
        break
      default:
        url = ''
        break
    }

    if (url) {
      try {
        const response = await fetch(url)
        const data = await response.json()
        return {
          pestel_sub: data.pestel_sub,
          content: data.content,
        }
      } catch (error) {
        return {
          pestel_sub: '',
          content: '',
        }
      }
    } else {
      return {
        pestel_sub: '',
        content: '',
      }
    }
  }

  const labelEdit = (label, id, value) => {
    const field = getFieldByLabel(label)
    updatePestelInfo(field, id, 'pestel_sub', value)
  }

  const textEdit = (label, id, value) => {
    const field = getFieldByLabel(label)
    updatePestelInfo(field, id, 'content', value)
  }
  const removeItem = (label, j) => {
    const field = getFieldByLabel(label)
    removePestelInfo(field, j)
  }

  const addItem = async (label) => {
    const generateData = await getPartialGenerate(label)

    const field = getFieldByLabel(label)
    await addPestelInfo(field, generateData)
  }
  // // 測試用

  const regenerateItem = async (label, j) => {
    const generateData = await getPartialGenerate(label)
    const field = getFieldByLabel(label)

    updatePestelInfo(field, j, 'pestel_sub', generateData.pestel_sub)
    updatePestelInfo(field, j, 'content', generateData.content)
  }

  return (
    <div className="edit-block">
      <div className="row">
        {module.map((item, index) => (
          <div
            key={index}
            className={`stage-section ${
              boardStyle === 'list' ? 'board-row' : 'board-column'
            }`}
          >
            <div className="color-card">
              <div className="color-card-body">
                <SectionTitle
                  setting={{
                    title: item.pestel,
                    // icon: cardList[cardKey].icon,
                    edit: false,
                  }}
                />
                <div className="card-wrapper">
                  <div className="module-control">
                    <Card
                      setting={{
                        labelID: index,
                        label: index,
                        content: item.pestel_info,
                        labelEdit,
                        textEdit,
                        addItem,
                        removeItem,
                        regenerateItem,
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

SuggestCard.propTypes = {
  setting: PropTypes.shape().isRequired,
}

export default SuggestCard
