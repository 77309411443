import React, { useState, useEffect, useContext } from 'react'
import { v4 as uuid } from 'uuid'
import { EditWrapper } from '../../components'
import { DraftContext } from '../../components/ContextProvider'
import ToolBar from '../../components/ToolBar'
import EditorArea from '../../components/EditorArea'

function Product() {
  const { draft } = useContext(DraftContext)
  const [module] = useState(
    draft.draft_id && draft
      ? draft
      : { module2: { weak: { [uuid()]: { cards: [] } } } }
  )

  const [records, setRecords] = useState([])

  useEffect(() => {
    setRecords(module.module2.weak.history && module.module2.weak.history)
  }, [module])

  return (
    <EditWrapper
      setting={{
        showInput: module.show_input,
        moduleTitle: module.title,
        moduleKeyword: module.module2.keyword,
        records,
      }}
    >
      <>
        <ToolBar
          setting={{
            title: '設計痛點',
            showAdd: false,
          }}
        />
        <EditorArea
          setting={{
            module: module.module2.weak,
            columnClass: 'column3x',
            wrapperClass: 'color-card shadow-block',
          }}
        />
      </>
    </EditWrapper>
  )
}

export default Product
