import React, { useState, useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import { useLocation, useNavigate } from 'react-router-dom'
import { Button, Image } from 'react-bootstrap'
import { DraftContext } from './ContextProvider'
import SpinnerLoading from './SpinnerLoading'

function SideBar() {
  const { currentID, setDraftId } = useContext(DraftContext)
  const navigate = useNavigate()
  const [sidebarNav] = useState([
    {
      name: '市場研究',
      list: [
        {
          id: 1,
          icon: 'nav-icon01.svg',
          title: 'PESTEL',
          href: '/module1',
          placeholder: '電動車/美國/上班族美國/上班族 ...',
        },
        {
          id: 2,
          icon: 'nav-icon02.svg',
          title: '競品分析',
          placeholder: '分析在 OO 市場的 OO 產業/服務',
          href: '/module2/product',
        },
      ],
    },
    {
      name: '使用者研究',
      list: [
        {
          id: 3,
          icon: 'nav-icon03.svg',
          title: '顧客旅程',
          href: '/module3',
          placeholder: '分析在 OO 市場的 OO 產業/服務',
        },
        {
          id: 4,
          icon: 'nav-icon04.svg',
          title: '人物誌',
          href: '/module4',
          placeholder: '穿著和外型為 OO 的 OO 角色',
        },
      ],
    },
  ])

  return (
    <div className="sidebar">
      <div className="scroll-sidebar">
        <div className="sidebar-wrap">
          <div className="sidebar-header">
            <Button
              className="sidebar-mainmenu btn-text return-btn"
              onClick={() => {
                setDraftId(0)
                navigate('/list')
              }}
            >
              <span
                className="svgIcon"
                style={{
                  maskImage: `url('/images/svgicons/return.svg')`,
                }}
              >
                return
              </span>
              返回專案列表
            </Button>
          </div>
          <div className="sidebar-nav">
            <ul>
              {sidebarNav.map((mainnav, index) => (
                <li key={`mainnav${index}`}>
                  <div className="nav-title">{mainnav.name}</div>
                  <ul className="mainnav-ul">
                    {mainnav.list.map((nav, i) => (
                      <NavButton
                        setting={{ nav, currentID }}
                        key={`nav-${i}`}
                      />
                    ))}
                  </ul>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

function NavButton({ setting }) {
  const { nav } = setting
  const { currentID, setCurrentID } = useContext(DraftContext)
  const location = useLocation()
  const navigate = useNavigate()
  const [openSubmenu, setOpenSubmenu] = useState(currentID === nav.id)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setTimeout(() => {
      setLoading(false)
    }, 1000)
  }, [])

  return (
    <li className={`sidebar-li ${openSubmenu ? 'active' : ''}`}>
      <Button
        className={`sidebar-mainmenu ${nav.sub ? 'hassub' : ''}`}
        active={location.pathname === nav.href}
        onClick={() => {
          if (nav.sub) {
            setOpenSubmenu(!openSubmenu)
          } else {
            setCurrentID(nav.id)
            navigate(nav.href)
          }
        }}
      >
        <Image src={`/images/svgicons/${nav.icon}`} alt="" />
        {nav.title}
        <div className={`spinner fade ${loading ? 'show' : ''}`}>
          <SpinnerLoading />
        </div>
      </Button>
      {nav.sub && (
        <ul className={`sidebar-subnav ${openSubmenu ? '' : 'd-none'}`}>
          {nav.sub.map((subnav, subIndex) => (
            <li key={`subnav-${subIndex}`}>
              <Button
                className="sidebar-submenu"
                active={location.pathname === subnav.href}
                onClick={() => {
                  setCurrentID(nav.id)
                  navigate(subnav.href)
                }}
              >
                {subnav.title}
              </Button>
            </li>
          ))}
        </ul>
      )}
    </li>
  )
}

NavButton.propTypes = {
  setting: PropTypes.shape().isRequired,
}

// SideBar.propTypes = {
//   setting: PropTypes.shape().isRequired,
// }

export default SideBar
