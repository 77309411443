import React, { useContext, useEffect, useState } from 'react'
import { EditWrapper } from '../../components'
import { DraftContext } from '../../components/ContextProvider'
import Subnav from './Subnav'
import TableRow from './TableRow'
import TableColumn from './TableColumn'
import { activeInput } from '../../lib/utility'
import useCompetitiveStore from '../../store/useCompetitiveStore'
import {
  addItem,
  deleteCard,
  textEdit,
  arrayEdit,
  initCompetitive,
} from '../../components/CompetitiveUtils'

function Product() {
  const { draft } = useContext(DraftContext)
  const [module] = useState(draft.draft_id && draft ? draft : {})
  const [records, setRecords] = useState([])
  const [boardStyle, setBoardStyle] = useState('row')
  const { competitive, setField, price_symbol } = useCompetitiveStore(
    (state) => ({
      competitive: state.competitive,
      setField: state.setField,
      price_symbol: state.price_symbol,
    })
  )

  const boards = [
    {
      id: 1,
      title: '橫列比對',
      image: '/images/boards/board-row.svg',
      key: 'row',
    },
    {
      id: 2,
      title: '直欄比對',
      image: '/images/boards/board-column.svg',
      key: 'column',
    },
  ]

  const switchBoard = (board) => {
    setBoardStyle(board)
  }

  useEffect(() => {
    if (module.module2) {
      setRecords(
        module.module2.product.history && module.module2.product.history
      )
    }
  }, [module])

  useEffect(() => {
    activeInput()
  }, [])

  useEffect(() => {
    if (competitive.length === 0) {
      initCompetitive(setField)
    }
  }, [competitive, setField])

  const reorder = (cardlist, startIndex, endIndex) => {
    const result = Array.from(cardlist)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    return result
  }

  const onDragEnd = (result) => {
    const { source, destination } = result

    if (!destination) {
      return
    }

    setField(
      'competitive',
      reorder(competitive, source.index, result.destination.index)
    )
  }

  return (
    module.module2 && (
      <EditWrapper
        setting={{
          showInput: module.show_input,
          moduleTitle: module.title,
          moduleKeyword: module.module2.keyword,
          records,
          boards,
          boardStyle,
          switchBoard,
        }}
      >
        <>
          <Subnav setting={{ currentID: 1 }} />
          <div className="tab-panel">
            {boardStyle === 'row' && (
              <TableRow
                setting={{
                  module: competitive,
                  onDragEnd,
                  addItem: (labelID) => addItem(labelID, competitive, setField),
                  deleteCard: (labelID) =>
                    deleteCard(labelID, competitive, setField),
                  textEdit: (id, value, label) =>
                    textEdit(id, value, label, competitive, setField),
                  arrayEdit: (label, value, type, i) =>
                    arrayEdit(label, value, type, i, competitive, setField),
                  setIsLoading: () => {},
                  priceType: price_symbol,
                  setPriceType: (value) => {
                    setField('price_symbol', value)
                  },
                }}
              />
            )}

            {boardStyle === 'column' && (
              <TableColumn
                setting={{
                  module: competitive,
                  onDragEnd,
                  addItem: (labelID) => addItem(labelID, competitive, setField),
                  deleteCard: (labelID) =>
                    deleteCard(labelID, competitive, setField),
                  textEdit: (id, value, label) =>
                    textEdit(id, value, label, competitive, setField),
                  arrayEdit: (label, value, type, i) =>
                    arrayEdit(label, value, type, i, competitive, setField),
                  setIsLoading: () => {},
                  priceType: price_symbol,
                }}
              />
            )}
          </div>
        </>
      </EditWrapper>
    )
  )
}

export default Product
