import React from 'react'
import PropTypes from 'prop-types'
import { Row } from 'react-bootstrap'

function Section({ setting }) {
  const { list } = setting

  return (
    <div className="section-block-list">
      <Row>
        {list.map((content, index) => (
          <div className="section-block" key={index}>
            <div className="inner">
              <div className="" dangerouslySetInnerHTML={{ __html: content }} />
            </div>
          </div>
        ))}
      </Row>
    </div>
  )
}

Section.propTypes = {
  setting: PropTypes.shape().isRequired,
}

export default Section
