import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, Image, DropdownButton } from 'react-bootstrap'
import { ToastContext } from './ContextProvider'

function HeaderAccount() {
  const navigate = useNavigate()
  const { setToast } = useContext(ToastContext)

  const loginOut = async () => {
    setToast({ show: true, text: '已登出' })
    navigate('/')
  }

  return (
    <DropdownButton
      id="dropdown-button-drop-end"
      align="start"
      title={
        <div id="accountHeader" className="account">
          <div className="account-avatar">T</div>
          <span>帳號</span>
        </div>
      }
    >
      <div className="account-card toggle-wrap shadow-block">
        <div className="form-group d-flex flex-wrap">
          <div className="avatar-icon">
            <Image
              src="/images/svgicons/account.svg"
              alt=""
              className="w-100"
            />
          </div>
          <div className="account-name">
            帳號名稱
            <br />
            ABC@gmail.com
          </div>
        </div>
        <ul>
          <li>
            <a href="/dashboard" className="primary-link">
              Dashboard
            </a>
          </li>
          <li>
            <Button className="btn-text" onClick={() => loginOut()}>
              登出
            </Button>
          </li>
        </ul>
      </div>
    </DropdownButton>
  )
}

export default HeaderAccount
