import React, { useState, useEffect, useContext } from 'react'
import { v4 as uuid } from 'uuid'
import PropTypes from 'prop-types'
import { DraftContext } from './ContextProvider'
import DragWrapper from './DragWrapper'

function EditorArea({ setting }) {
  const {
    health_advocate_profile,
    user_info,
    showToolbar = false,
    quote,
    newList,
    boardStyle = 'horizon',
  } = setting
  const { cardList, setCardList, editDetail, componentType } =
    useContext(DraftContext)

  const [moduleList, setModuleList] = useState(newList)
  const [constList, setConstList] = useState([])

  const transToList = (arr) => {
    if (!arr) return ''
    const listHtml = arr.map((item) => `<li>${item}</li>`).join('')
    return `<ul>${listHtml}</ul>`
  }

  useEffect(() => {
    setCardList(newList)
    setConstList([
      {
        title: '目標',
        content: transToList(health_advocate_profile.user_end_goal),
      },
      {
        title: '偏好',
        content: transToList(health_advocate_profile.lifestyle_preferences),
      },
      {
        title: '痛點',
        content: transToList(health_advocate_profile.painpoints),
      },
      { title: '需求', content: transToList(health_advocate_profile.needs) },
    ])
  }, [moduleList])

  // undo and redo
  // const {
  //   cursor,
  //   setCursor,
  //   history,
  //   setHistory,
  //   recoverTarget,
  //   setRecoverTarget,
  // } = useContext(DraftContext)

  // useEffect(() => {
  //   setHistory([module])
  //   setCursor(-1)
  //   setRecoverTarget(-1)
  // }, [])

  // useEffect(() => {
  //   if (recoverTarget !== -1) {
  //     setCardList(history[recoverTarget])
  //   }
  // }, [recoverTarget])

  // useEffect(() => {
  //   if (recoverTarget >= 0) {
  //     setCursor(recoverTarget)
  //     setRecoverTarget(-1)
  //   } else {
  //     setHistory((prevState) => [...prevState.slice(0, cursor + 1), module])
  //     setCursor((prevState) => prevState + 1)
  //   }
  // }, [cardList])

  const reorder = (cardlist, startIndex, endIndex) => {
    const result = Array.from(cardlist)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    return result
  }

  const copy = (source, destination, droppableSource, droppableDestination) => {
    const sourceClone = Array.from(source)
    const destClone = Array.from(destination)
    const item = sourceClone[droppableSource.index]
    destClone.splice(droppableDestination.index, 0, { ...item, id: uuid() })

    return destClone
  }

  const move = (
    list,
    source,
    destination,
    droppableSource,
    droppableDestination
  ) => {
    const sourceClone = Array.from(source)
    const destClone = Array.from(destination)
    const [removed] = sourceClone.splice(droppableSource.index, 1)
    destClone.splice(droppableDestination.index, 0, removed)

    const result = list
    result[droppableSource.droppableId] = {
      ...list[droppableSource.droppableId],
      cards: sourceClone,
    }
    result[droppableDestination.droppableId] = {
      ...list[droppableDestination.droppableId],
      cards: destClone,
    }

    return result
  }

  const onDragEnd = (result) => {
    const { source, destination } = result

    if (!destination) {
      return
    }

    switch (source.droppableId) {
      case destination.droppableId:
        // 拖拉排序
        setCardList({
          ...cardList,
          [destination.droppableId]: {
            ...cardList[destination.droppableId],
            cards: reorder(
              cardList[destination.droppableId].cards,
              result.source.index,
              result.destination.index
            ),
          },
        })

        break
      case 'elements':
        // 拖拉元素進卡片
        setModuleList({
          ...moduleList,
          [destination.droppableId]: {
            ...moduleList[destination.droppableId],
            cards: copy(
              editDetail[componentType],
              moduleList[destination.droppableId].cards,
              source,
              destination
            ),
          },
        })

        break
      default:
        setCardList(
          move(
            cardList,
            cardList[source.droppableId].cards,
            cardList[destination.droppableId].cards,
            source,
            destination
          )
        )

        break
    }
  }

  // 從拖曳區移除元件
  const removeCard = (cardKey, id) => {
    setModuleList({
      ...moduleList,
      [cardKey]: {
        ...moduleList[cardKey],
        cards: moduleList[cardKey].cards.filter(
          (item) => item.id !== id && item
        ),
      },
    })
  }

  // 變更文字框資訊
  const handleInputChange = (label, id, value) => {
    setCardList({
      ...cardList,
      [label]: {
        ...cardList[label],
        cards: cardList[label].cards.map((l) => (l.id === id ? value : l)),
      },
    })
  }

  return (
    <DragWrapper
      setting={{
        user_info,
        quote,
        moduleList,
        onDragEnd,
        handleInputChange,
        removeCard,
        showToolbar,
        constList,
        health_advocate_profile,
        characteristics_and_focus:
          health_advocate_profile?.characteristics_and_focus,
        boardStyle,
      }}
    />
  )
}

EditorArea.propTypes = {
  setting: PropTypes.shape().isRequired,
}

export default EditorArea
