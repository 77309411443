import React, { useState, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { Form, InputGroup, Image } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import { LoadingButton } from '../components'
import { ToastContext } from '../components/ContextProvider'

function EditPassword() {
  const { setToast } = useContext(ToastContext)
  const navigate = useNavigate()
  const [reveal, setReveal] = useState(false)
  const [data, setData] = useState({
    password: '',
    confirm: '',
  })

  const onDataChange = (event) => {
    setData({ ...data, [event.target.name]: event.target.value })
  }

  const handleLogin = async () => {
    if (data.confirm !== data.password) {
      setToast({ show: true, text: '更改失敗，請確認兩次密碼是否相同' })
    } else {
      setToast({ show: true, text: '密碼更新成功' })
      navigate('/')
    }
  }

  const fields = [
    {
      label: '密碼',
      type: 'password',
      name: 'password',
      placeholder: '密碼',
    },
    {
      label: '確認密碼',
      type: 'password',
      name: 'confirm',
      placeholder: '確認密碼',
    },
  ]

  return (
    <div className="page-center">
      <div className="block">
        <div className="d-flex flex-wrap align-items-center">
          <div className="left">
            <Image src="/images/d_logo_full.png" className="dai-logo" alt="" />
          </div>
          <div className="right">
            <Form>
              <h2>重設密碼</h2>
              {fields.map((field) => (
                <Form.Group key={field.name} className="form-group">
                  {field.type === 'password' ? (
                    <InputGroup className="">
                      <Form.Control
                        name={field.name}
                        type={reveal ? 'text' : field.type}
                        onChange={onDataChange}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter' && !e.isComposing) handleLogin()
                        }}
                        placeholder={field.placeholder}
                      />
                      <InputGroup.Text>
                        <FontAwesomeIcon
                          title={reveal ? '點擊以隱藏密碼' : '點擊以顯示密碼'}
                          icon={reveal ? faEye : faEyeSlash}
                          onClick={() => setReveal(!reveal)}
                        />
                      </InputGroup.Text>
                    </InputGroup>
                  ) : (
                    <Form.Control
                      name={field.name}
                      type={reveal ? 'text' : field.type}
                      onChange={onDataChange}
                      onKeyDown={() => {}}
                      placeholder={field.placeholder}
                    />
                  )}
                </Form.Group>
              ))}
              <hr className="line" />
              <div className="form-group">
                <LoadingButton
                  className="w-100"
                  variant="primary"
                  onClick={handleLogin}
                  disabled={!data.confirm || !data.password}
                  btnText="註冊"
                />
              </div>
              <div className="form-footer">
                <a href="/login" className="">
                  返回登入
                </a>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EditPassword
