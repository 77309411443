/* eslint-disable no-promise-executor-return */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useContext } from 'react'
import PropTypes from 'prop-types'
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd'
import { Image, Button, Form } from 'react-bootstrap'
import { DraftContext } from './ContextProvider'
import SidebarClose from './SidebarClose'
import AllEditor from './AllEditor'
import Gallery from '../tools/Gallery'
import StoryCard from './StoryCard'

function DragWrapper({ setting }) {
  const {
    // module = {},
    moduleList = {},
    user_info,
    quote = [],
    characteristics_and_focus = [],
    onDragEnd,
    handleInputChange = () => {},
    removeCard = () => {},
    showToolbar = false,
    constList,
  } = setting
  const {
    editDetail,
    componentType,
    setComponentType,
    sidebar,
    showWarn,
    setShowWarn,
  } = useContext(DraftContext)
  const [showDetail] = useState(false)
  const [editList, setEditList] = useState(editDetail[componentType])

  const setType = (type) => {
    setComponentType(type)
    setEditList(editDetail[type])
  }

  const dragAreaStyle = (isDraggingOver) => ({
    border: isDraggingOver ? '1px dashed #4099ff' : '',
  })

  const dragItemStyle = (isDragging, draggableStyle) => ({
    userSelect: 'none',
    border: isDragging ? '2px solid #717477' : 'none',
    ...draggableStyle,
  })

  const transToQuote = (array) => {
    let res = ''
    for (let index = 0; index < array.length; index += 1) {
      res += `<blockquote>${array[index]}</blockquote>`
    }
    return res
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      {showToolbar && (
        <>
          <Component setting={{ setType }} />
          <ComponentDetail
            setting={{
              show: showDetail,
              list: editList,
            }}
          />
        </>
      )}
      <div className={`edit-block ${sidebar ? 'open' : ''}`}>
        <div className="row">
          <div className="d-flex flex-wrap">
            <div className="story-left">
              <Gallery setting={{ imgs: user_info?.gallery || [], setType }} />
              <ul className="spec">
                {user_info.name && (
                  <li>
                    <span className="label-text">姓名</span>
                    <Form.Control
                      name="name"
                      type="text"
                      placeholder="姓名"
                      defaultValue={user_info.name}
                      onChange={() => {}}
                    />
                  </li>
                )}
                {user_info.age && (
                  <li>
                    <span className="label-text">年齡</span>
                    <Form.Control
                      name="age"
                      type="text"
                      placeholder="年齡"
                      defaultValue={user_info.age}
                      onChange={() => {}}
                    />
                  </li>
                )}
                {user_info.occupation && (
                  <li>
                    <span className="label-text">職業</span>
                    <Form.Control
                      name="occupation"
                      type="text"
                      placeholder="職業"
                      defaultValue={user_info.occupation}
                      onChange={() => {}}
                    />
                  </li>
                )}
                {user_info.family_status && (
                  <li>
                    <span className="label-text">家庭狀況</span>
                    <Form.Control
                      name="family_status"
                      type="text"
                      placeholder="家庭狀況"
                      defaultValue={user_info.family_status}
                      onChange={() => {}}
                    />
                  </li>
                )}
                {user_info.frequently_used_items && (
                  <li>
                    <span className="label-text">常用物件</span>
                    <Form.Control
                      name="commonItems"
                      type="text"
                      placeholder="常用物件"
                      defaultValue={user_info.frequently_used_items}
                      onChange={() => {}}
                    />
                  </li>
                )}
              </ul>
            </div>
            <div className="story-right">
              <div className="card-wrapper">
                <StoryCard
                  setting={{
                    label: '動機與行為',
                    text: user_info.motivations_and_behaviors,
                  }}
                />
                <StoryCard
                  setting={{
                    label: '',
                    text: transToQuote(quote),
                  }}
                />
              </div>
              <div className="badges-group">
                {characteristics_and_focus.map((item, index) => (
                  <span key={index} className="badge rounded-pill bg-white">
                    {item}
                  </span>
                ))}
              </div>
            </div>
          </div>

          <div className="stage-section">
            <div className="card-wrapper">
              <div className="module-control">
                <div className="row row-item ">
                  {constList.map((content, index) => (
                    <div className="col-6 mb-5" key={`constList_${index}`}>
                      <AllEditor
                        setting={{
                          type: 'story-card',
                          content,
                          handleInputChange,
                          index,
                        }}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>

          {Object.keys(moduleList).map((cardKey) => (
            <Droppable key={cardKey} droppableId={cardKey}>
              {(cprovided, csnapshot) => (
                <div
                  key={cardKey}
                  className="stage-section"
                  ref={cprovided.innerRef}
                  style={dragAreaStyle(csnapshot.isDraggingOver)}
                >
                  <div className="card-wrapper">
                    {moduleList[cardKey].cards.length === 0 ? (
                      <div className="empty-placeholder">Drop items here</div>
                    ) : (
                      moduleList[cardKey].cards.map(
                        ({ id, type, content }, index) => (
                          <Draggable key={id} draggableId={id} index={index}>
                            {(dragProvided, dragSnapshot) => (
                              <div
                                ref={dragProvided.innerRef}
                                {...dragProvided.draggableProps}
                                style={dragItemStyle(
                                  dragSnapshot.isDragging,
                                  dragProvided.draggableProps.style
                                )}
                                className="dragrow has-drag"
                              >
                                <div
                                  className="drag-bar"
                                  {...dragProvided.dragHandleProps}
                                >
                                  <div className="module-control-btn">
                                    <Image
                                      src="/images/toolicons/drag.svg"
                                      alt=""
                                    />
                                  </div>
                                </div>
                                <div className="module-control">
                                  <div
                                    className="card-tool"
                                    style={{
                                      left: '10%',
                                      right: 'auto',
                                    }}
                                  >
                                    <Button
                                      className="btn-text"
                                      onClick={() => {
                                        setShowWarn({
                                          ...showWarn,
                                          show: true,
                                          text: '確定移除元件？',
                                          func: () => {
                                            removeCard(cardKey, id)
                                          },
                                        })
                                      }}
                                    >
                                      <div
                                        className="svgIcon"
                                        style={{
                                          maskImage: `url('/images/svgicons/trash.svg')`,
                                        }}
                                      >
                                        刪除
                                      </div>
                                    </Button>
                                  </div>
                                  <AllEditor
                                    setting={{
                                      id,
                                      label: cardKey,
                                      type,
                                      content,
                                      handleInputChange,
                                      index,
                                    }}
                                  />
                                </div>
                              </div>
                            )}
                          </Draggable>
                        )
                      )
                    )}
                    {cprovided.placeholder}
                  </div>
                </div>
              )}
            </Droppable>
          ))}
        </div>
      </div>
    </DragDropContext>
  )
}

// 拖拉元件
function Component({ setting }) {
  const { setType } = setting
  const [currentEle, setCurrentEle] = useState(0)
  const { editItems, openSidebar, sidebar } = useContext(DraftContext)

  return (
    <div className={`edit-components ${sidebar ? 'show' : ''}`}>
      <div className="scroll-sidebar">
        <div className="sidebar-wrap">
          {editItems.map((item, index) => (
            <Button
              key={`item-${index}`}
              className="edit-item btn-text"
              active={item.id === currentEle}
              onClick={() => {
                setType(item.type)
                setCurrentEle(item.id)
                openSidebar()
              }}
            >
              <div className="edit-item-icon">
                <Image
                  src={`/images/toolicons/${item.icon}`}
                  className="mx-0"
                  alt=""
                />
              </div>
              {item.name}
            </Button>
          ))}
        </div>
      </div>
    </div>
  )
}

function ComponentDetail({ setting }) {
  const { list } = setting
  const { sidebar } = useContext(DraftContext)

  // const getItemStyle = (isDragging, draggableStyle) => ({
  //   userSelect: 'none',
  //   background: isDragging ? 'transparent' : '',
  //   ...draggableStyle,
  // })

  // const getListStyle = (isDraggingOver) => ({
  //   background: isDraggingOver ? '#00FF00' : '#FF0000',
  // })

  return (
    <div className={`component-detail ${sidebar ? 'show' : ''}`}>
      <div className="inner position-relative">
        <div className="scroll-sidebar">
          <div className="sidebar-wrap">
            <Droppable droppableId="elements" isDropDisabled>
              {(provided) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  // style={getListStyle(snapshot.isDraggingOver)}
                >
                  {list.map((item, index) => (
                    <Draggable
                      key={`${item.id}`}
                      draggableId={`${item.id}`}
                      index={index}
                    >
                      {(dragProvided, dragSnapshot) => (
                        <React.Fragment key={index}>
                          <div
                            className="snippet-item"
                            ref={dragProvided.innerRef}
                            {...dragProvided.draggableProps}
                            {...dragProvided.dragHandleProps}
                            // style={getItemStyle(
                            //   dragSnapshot.isDragging,
                            //   dragProvided.draggableProps.style
                            // )}
                          >
                            <div className="elememt">
                              {item.img ? (
                                <Image
                                  src={`/images/tools/${item.img}`}
                                  alt=""
                                  className="w-100"
                                />
                              ) : (
                                item.name
                              )}
                            </div>
                          </div>
                          {dragSnapshot.isDragging && (
                            <div className="elememt clone-item">
                              <Image
                                src={`/images/tools/${item.img}`}
                                alt=""
                                className="w-100"
                              />
                            </div>
                          )}
                        </React.Fragment>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </div>
        </div>
        <SidebarClose />
      </div>
    </div>
  )
}

ComponentDetail.propTypes = {
  setting: PropTypes.shape().isRequired,
}

Component.propTypes = {
  setting: PropTypes.shape().isRequired,
}

DragWrapper.propTypes = {
  setting: PropTypes.shape().isRequired,
}

export default DragWrapper
