import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import ImgCard from './ImgCard'

function Gallery({ setting }) {
  const { imgs = [], handleOpen } = setting

  // loading
  const [itemLoading, setItemLoading] = useState(true)
  useEffect(() => {
    if (itemLoading) {
      setTimeout(() => {
        setItemLoading(false)
      }, 1000)
    }
  }, [itemLoading])

  return (
    <div className="imgs-row">
      {imgs.map((pic, itemIndex) => (
        <div className="img-item">
          {itemLoading && (
            <div className="img-loading">
              <img src="/images/image-loading.svg" alt="" />
            </div>
          )}
          <div className={`fade ${itemLoading ? '' : 'show'}`}>
            <ImgCard
              key={`img-${itemIndex}`}
              setting={{ img: pic, handleOpen }}
            />
          </div>
        </div>
      ))}
    </div>
  )
}

Gallery.propTypes = {
  setting: PropTypes.shape().isRequired,
}

export default Gallery
