import React from 'react'
import PropTypes from 'prop-types'
import { Star, Text, Gallery } from '../../tools'

function InovationColumn({ setting }) {
  const { module, list_index } = setting

  return (
    <>
      {module.map((item, index) => (
        <div className="inovation-card">
          <div className="inner d-flex">
            <div className="column-left">
              <div className="badge rounded-pill bg-white">
                {`方案${index + 1}`}
              </div>
              <div className="flex-1">
                <Text
                  setting={{
                    content: [
                      `<p><strong>${item.headline}</strong></p><p>${item.ideas}</p>`,
                    ],
                    label: list_index,
                  }}
                />
              </div>
              <div className="star">
                <Star
                  setting={{
                    star: item.star,
                  }}
                />
              </div>
            </div>
            <div className="column-right">
              <Gallery
                setting={{
                  imgs: item.images || [],
                }}
              />
            </div>
          </div>
        </div>
      ))}
    </>
  )
}

InovationColumn.propTypes = {
  setting: PropTypes.shape().isRequired,
}

export default InovationColumn
