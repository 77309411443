import React from 'react'
import PropTypes from 'prop-types'
import { Image, OverlayTrigger, Tooltip, Form } from 'react-bootstrap'

function SectionTitle({ setting }) {
  const { title, icon = '', tooltop_text, edit } = setting

  return (
    <div className="color-title label-title">
      {icon && (
        <div className="img w-100">
          <Image src={icon} alt="" />
        </div>
      )}
      {edit ? (
        <Form.Control
          name="title"
          type="text"
          placeholder="標題"
          defaultValue={title}
          onChange={() => {}}
        />
      ) : (
        title
      )}
      &nbsp;
      {tooltop_text && (
        <OverlayTrigger
          placement="right"
          delay={{ show: 250, hide: 400 }}
          overlay={
            <Tooltip
              style={{
                zIndex: '9999',
              }}
            >
              {tooltop_text}
            </Tooltip>
          }
        >
          <Image
            src="/images/svgicons/tooltip.svg"
            alt=""
            className="img-fluid"
          />
        </OverlayTrigger>
      )}
    </div>
  )
}

SectionTitle.propTypes = {
  setting: PropTypes.shape().isRequired,
}

export default SectionTitle
