import React, { useState, useEffect, useContext, useRef } from 'react'
// import { v4 as uuid } from 'uuid'
import { Button } from 'react-bootstrap'
import { DraftContext } from '../../components/ContextProvider'
import { EditWrapper } from '../../components'
import Subnav from './Subnav'
import InovationRow from './InovationRow'
import InovationColumn from './InovationColumn'
import { activeInput, initMultipleDataFromJson } from '../../lib/utility'
import { useInovationStore } from '../../store'

function Inovation() {
  const { draft } = useContext(DraftContext)
  const [module] = useState(draft.draft_id && draft ? draft : {})
  const [tab, setTab] = useState(0)
  const [boardStyle, setBoardStyle] = useState('row')
  const boards = [
    {
      id: 1,
      title: '創新機會方案橫式',
      image: '/images/boards/inovation-row.svg',
      key: 'row',
    },
    {
      id: 2,
      title: '創新機會方案直式',
      image: '/images/boards/inovation-column.svg',
      key: 'column',
    },
  ]
  const categoryRef = useRef([])
  const {
    product_innovation,
    service_innovation,
    business_model,
    infra_develop,
    tab_list,
    setField,
  } = useInovationStore((store) => ({
    product_innovation: store.product_innovation,
    service_innovation: store.service_innovation,
    business_model: store.business_model,
    infra_develop: store.infra_develop,
    tab_list: store.tab_list,
    setField: store.setField,
  }))

  const switchBoard = (board) => {
    setBoardStyle(board)
  }

  // const [list, setList] = useState(
  //   module.module2 ? module.module2.inovation.category : {}
  // )

  const [records, setRecords] = useState([])
  const moveItem = (data, sourceIndex, destinationIndex) => {
    const copyData = JSON.parse(JSON.stringify(data))
    const moveData = copyData.splice(sourceIndex, 1)[0]
    copyData.splice(destinationIndex, 0, moveData)
    return copyData
  }
  const moveImage = (data, sourceIndex, destinationIndex, imageIndex) => {
    const copyData = JSON.parse(JSON.stringify(data))
    const sliceData = copyData[imageIndex].images.splice(sourceIndex, 1)[0]
    copyData[imageIndex].images.splice(destinationIndex, 0, sliceData)
    return copyData
  }
  const updateData = (index, source, destination, imageIndex = null) => {
    const modules = [
      product_innovation,
      service_innovation,
      business_model,
      infra_develop,
    ]
    const setFields = [
      'product_innovation',
      'service_innovation',
      'business_model',
      'infra_develop',
    ]

    const moveFunction = imageIndex ? moveImage : moveItem
    const updatedData = moveFunction(
      modules[index],
      source.index,
      destination.index,
      imageIndex
    )
    setField(setFields[index], updatedData)
  }
  const handleList = (source, destination, index) => {
    if (source.droppableId.includes('img-group')) {
      const imageIndex = source.droppableId.split('-').slice(2).join('-')
      updateData(index, source, destination, imageIndex)
    } else {
      updateData(index, source, destination)
    }
  }

  const renderCategoryItem = (id, title, obj, listIndex) => (
    <div
      className="category-item"
      id={`category${id}`}
      ref={(el) => {
        categoryRef.current[listIndex] = el
      }}
    >
      <h2 className="page-title mb-5">{title}</h2>
      {boardStyle === 'row' ? (
        <InovationRow
          setting={{
            module: obj,
            handleList,
            list_index: listIndex,
          }}
        />
      ) : (
        <InovationColumn setting={{ module: obj, list_index: listIndex }} />
      )}
    </div>
  )

  useEffect(() => {
    if (module.module2) {
      setRecords(
        module.module2.inovation.history && module.module2.inovation.history
      )
    }
  }, [module])
  const scrollToChangeTab = () => {
    window.addEventListener('scroll', () => {
      if (categoryRef.current.length > 0) {
        categoryRef.current.forEach((item, index) => {
          if (item && item.offsetTop - 70 <= window.scrollY) {
            setTab(index)
          }
        })
      }
    })
  }

  useEffect(() => {
    activeInput()

    scrollToChangeTab()
    const json_urls = [
      {
        url: '/data/competitive_product_analysis/product_innovation.json',
        dataName: 'product_innovation',
        target: '2-4-1_product-innovation',
      },
      {
        url: '/data/competitive_product_analysis/service_innovation.json',
        dataName: 'service_innovation',
        target: '2-4-2_service-innovation',
      },
      {
        url: '/data/competitive_product_analysis/business_model.json',
        dataName: 'business_model',
        target: '2-4-3_business-model',
      },
      {
        url: '/data/competitive_product_analysis/infra_develop.json',
        dataName: 'infra_develop',
        target: '2-4-4_infra-develop',
      },
    ]
    initMultipleDataFromJson(json_urls, setField)
  }, [])

  return (
    module.module2 && (
      <EditWrapper
        setting={{
          showInput: module.show_input,
          moduleTitle: module.title,
          moduleKeyword: module.module2.keyword,
          records,
          boards,
          boardStyle,
          switchBoard,
        }}
      >
        <>
          <Subnav setting={{ currentID: 4 }} />
          <div className="tab-panel">
            {tab_list.length > 0 && (
              <div className="category-nav">
                <ul>
                  {tab_list.map((listitem, index) => (
                    <li key={`category${index}`}>
                      <Button
                        className={index === tab ? 'active' : ''}
                        onClick={() => {
                          setTab(index)
                          const element = document.getElementById(
                            `category${index}`
                          )
                          if (!element) return
                          window.scrollTo({
                            top: element.offsetTop - 20,
                            behavior: 'smooth',
                          })
                        }}
                      >
                        {listitem}
                      </Button>
                    </li>
                  ))}
                </ul>
              </div>
            )}
            <div className="edit-block">
              {renderCategoryItem(0, tab_list[0], product_innovation, 0)}
              {renderCategoryItem(1, tab_list[1], service_innovation, 1)}
              {renderCategoryItem(2, tab_list[2], business_model, 2)}
              {renderCategoryItem(3, tab_list[3], infra_develop, 3)}
            </div>
          </div>
        </>
      </EditWrapper>
    )
  )
}

export default Inovation
