import React from 'react'

function Footer() {
  return (
    <div id="footer">
      <p className="text-center">
        Copyright &copy; 2024 TDRI. all rights reserved.
      </p>
    </div>
  )
}

export default Footer
