import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { Image, Form, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { ImgCard, TextArea, BarChat, AutoHeightText } from '../tools'

function SuggestPersona({ setting }) {
  const {
    user_info,
    emotions = [],
    opportunities_and_painpoints = [],
    journeys,
  } = setting

  const bars = emotions.map((item) => item.score)

  const painpoints = opportunities_and_painpoints.map((item) => ({
    metaKey: 'painpoint',
    content: item.painpoint,
  }))

  const opportunities = opportunities_and_painpoints.map((item) => ({
    metaKey: 'opportunity',
    content: item.opportunity,
  }))

  const points = [...painpoints, ...opportunities]
  // svg size
  const ref = useRef(null)
  const [svgSize, setsvgSize] = useState({
    width: 1000,
    height: 300,
  })

  const getSize = () => {
    if (ref.current) {
      // const style = getComputedStyle(ref.current)
      // const height =
      //   ref.current.clientHeight -
      //   parseFloat(style.paddingTop) -
      //   parseFloat(style.paddingBottom)
      const height = 498
      const width = ref.current.clientWidth
      return { width, height }
    }
    return false
  }

  useEffect(() => {
    const observer = new ResizeObserver(() => {
      const size = getSize()
      if (size.width !== svgSize.width || size.height !== svgSize.height)
        setsvgSize(size)
    })
    observer.observe(ref.current)
    return () => ref.current && observer.unobserve(ref.current)
  }, [])

  return (
    <div ref={ref} className="stage-section">
      <div className="info-header">
        <div className="info-header-img">
          <ImgCard setting={{ img: user_info?.image }} />
        </div>
        <div className="info-header-spec">
          <ul className="spec">
            {user_info.name && (
              <li>
                <span className="label-text">姓名</span>
                <Form.Control
                  name="name"
                  type="text"
                  placeholder="姓名"
                  defaultValue={user_info.name}
                  onChange={() => {}}
                />
              </li>
            )}
            {user_info.age && (
              <li>
                <span className="label-text">年齡</span>
                <Form.Control
                  name="age"
                  type="text"
                  placeholder="年齡"
                  defaultValue={user_info.age}
                  onChange={() => {}}
                />
              </li>
            )}
            {user_info.male_or_female && (
              <li>
                <span className="label-text">性別</span>
                <Form.Control
                  name="male_or_female"
                  type="text"
                  placeholder="性別"
                  defaultValue={user_info.male_or_female}
                  onChange={() => {}}
                />
              </li>
            )}
            {user_info.occupation && (
              <li>
                <span className="label-text">職業</span>
                <AutoHeightText
                  setting={{
                    onChange: () => {},
                    value: user_info.occupation,
                    placeholder: '職業',
                    className: 'px-0 pt-0',
                  }}
                />
              </li>
            )}
            <li>
              <span className="label-text">任務</span>
              <AutoHeightText
                setting={{
                  onChange: () => {},
                  value: user_info.task,
                  placeholder: '任務',
                  className: 'px-0 pt-0',
                }}
              />
            </li>
          </ul>
        </div>
        <div className="info-header-info flex-1">
          <div className="label-text" style={{ marginLeft: '20px' }}>
            人物基本介紹：
          </div>
          <TextArea
            setting={{
              value: user_info.user_scenario,
              labelId: 1,
            }}
          />
        </div>
      </div>
      {/* end info-header */}
      <div className="info-body">
        <div className="info-item">
          <div className="color-title label-title">接觸點</div>
          <div className="row">
            {journeys &&
              journeys.map((item, index) => (
                <EventItem key={`event${index}`} setting={{ item }} />
              ))}
          </div>
        </div>
        <div className="info-item">
          <div className="color-title label-title">
            使用者情緒
            <OverlayTrigger
              placement="right"
              delay={{ show: 250, hide: 400 }}
              overlay={
                <Tooltip
                  style={{
                    zIndex: '9999',
                  }}
                >
                  使用者情緒反映使用者在不同階段的感受。低落的情緒點揭示改善體驗的機會；正面情緒則突顯體驗的強處，可供深化和擴展。
                </Tooltip>
              }
            >
              <Image
                src="/images/svgicons/tooltip.svg"
                alt=""
                className="img-fluid"
                style={{
                  marginLeft: '14px',
                }}
              />
            </OverlayTrigger>
          </div>
          <div className="bar-char">
            <BarChat
              setting={{
                emotions: bars,
                edit: true,
                ...svgSize,
              }}
            />
          </div>
        </div>
        <div className="info-item">
          <div className="color-title label-title">痛點與機會點</div>
          <div className="point-list">
            {points &&
              points.map((item, index) => (
                <div
                  className={`point-card ${
                    item.metaKey === 'painpoint' ? 'weak' : 'chance'
                  }`}
                  key={`ponit${index}`}
                >
                  <span>
                    <AutoHeightText
                      setting={{
                        onChange: () => {},
                        value: item.content,
                        placeholder:
                          item.metaKey === 'painpoint' ? '痛點' : '機會點',
                        className: '',
                      }}
                    />
                  </span>
                </div>
              ))}
          </div>
        </div>
      </div>
      {/* end info-body */}
    </div>
  )
}

function EventItem({ setting }) {
  const { item } = setting

  const array = [...item.action, ...[item.channels_and_stakeholders]]
  let textAreaList = ''
  array.forEach((innerItem) => {
    textAreaList += `<li>${innerItem}</li>`
  })
  textAreaList = `<ul>${textAreaList}</ul>`

  return (
    <div className="event-item">
      <div className="event-item-header">
        <Form.Control
          name="name"
          type="text"
          placeholder="行為標題"
          defaultValue={item.touchpoint}
          onChange={() => {}}
          className="text-center"
        />
      </div>
      <div className="event-item-info">
        <TextArea
          setting={{
            value: textAreaList,
            labelId: 1,
          }}
        />
      </div>
    </div>
  )
}

EventItem.propTypes = {
  setting: PropTypes.shape().isRequired,
}

SuggestPersona.propTypes = {
  setting: PropTypes.shape().isRequired,
}

export default SuggestPersona
