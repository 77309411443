import React from 'react'

function Pagination() {
  return (
    <nav aria-label="Page navigation">
      <ul className="pagination justify-content-center">
        <li className="page-item">
          <a className="page-link border-0" href="/list">
            <span
              className="svgIcon"
              style={{
                maskImage: `url('/images/arrow-left.svg')`,
              }}
            >
              Previous
            </span>
          </a>
        </li>
        <li className="page-item">
          <a className="page-link active" href="/list">
            1
          </a>
        </li>
        <li className="page-item">
          <a className="page-link" href="/list">
            2
          </a>
        </li>
        <li className="page-item">
          <a className="page-link" href="/list">
            3
          </a>
        </li>
        <li className="page-item">
          <a className="page-link border-0" href="/list">
            <span
              className="svgIcon"
              style={{
                maskImage: `url('/images/arrow-right.svg')`,
              }}
            >
              next
            </span>
          </a>
        </li>
      </ul>
    </nav>
  )
}

export default Pagination
