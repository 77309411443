/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Button from 'react-bootstrap/Button'

function LoadingButton(props) {
  const { variant, btnText, className, onClick, disabled } = props
  const [clicked, setclicked] = useState(false)
  return (
    <Button
      variant={variant}
      className={className}
      onClick={async (e) => {
        setclicked(true)
        await onClick(e)
        setclicked(false)
      }}
      disabled={clicked || disabled}
    >
      {clicked ? (
        <span className="spinner-border spinner-border-sm" />
      ) : (
        btnText
      )}
    </Button>
  )
}

LoadingButton.propTypes = {
  variant: PropTypes.string,
  btnText: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
}

export default LoadingButton
