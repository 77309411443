import React, { useState, useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFloppyDisk } from '@fortawesome/free-solid-svg-icons'
import { Button, Image, Row, Col, DropdownButton } from 'react-bootstrap'
import { DraftContext } from './ContextProvider'
import ImportBtn from './ImportBtn'

function ToolBar({ setting }) {
  const { openRecord = () => {}, openBoard = () => {} } = setting
  const {
    cardList,
    cursor,
    history,
    handleRecover,
    showGen,
    setShowGen,
    currentID,
  } = useContext(DraftContext)
  const [saveText, setSaveText] = useState(false)

  const showSaveText = () => {
    setSaveText(true)
    const timer = setTimeout(() => {
      setSaveText(false)
    }, 3000)
    return () => clearTimeout(timer)
  }

  useEffect(() => {
    showSaveText()
  }, [])

  return (
    <div className="edit-toolbar">
      <Row>
        <Col xs={12} lg={2}>
          <div className="d-flex align-items-center">
            <Button
              className="btn-primary"
              style={{
                marginRight: '10px',
                whiteSpace: 'nowrap',
              }}
              onClick={() => {
                setShowGen({
                  ...showGen,
                  show: true,
                  func: () => {},
                  isToolBarBtn: true,
                })
              }}
            >
              重新生成
            </Button>
            <Button
              className="btn-primary"
              style={{
                whiteSpace: 'nowrap',
              }}
              onClick={() => {
                showSaveText()
                console.log(cardList)
              }}
            >
              <FontAwesomeIcon
                icon={faFloppyDisk}
                className="mx-3"
                style={{ transform: 'translateY(1px)' }}
              />
              儲存
            </Button>
            <div
              className={`d-flex ${
                saveText ? '' : 'hidden'
              } saveText text-nowrap`}
            >
              <span className="toolbar-text">
                最後儲存時間2023年7月20日 下午2:00
              </span>
              <Button
                className="btn-text btn-link"
                onClick={() => openRecord()}
              >
                查看其他儲存紀錄
              </Button>
            </div>
          </div>
        </Col>
        <Col xs={12} lg={10}>
          <div className="d-flex justify-content-end align-items-center">
            <div className="module-setting">
              <ul className="toggle-wrap-nav nav-hor">
                <li>
                  <Button
                    disabled={!cursor}
                    className="btn-text"
                    onClick={() => handleRecover(cursor - 1)}
                  >
                    <Image src="/images/svgicons/undo.svg" alt="" />
                    UNDO
                  </Button>
                </li>
                <li>
                  <Button
                    className="btn-text"
                    disabled={cursor === history.length - 1}
                    onClick={() => handleRecover(cursor + 1)}
                  >
                    <Image src="/images/svgicons/redo.svg" alt="" />
                    REDO
                  </Button>
                </li>
              </ul>
            </div>
            <div className="module-setting">
              <ul className="toggle-wrap-nav nav-hor">
                <li>
                  <Button className="btn-text">
                    <div
                      className="svgIcon"
                      style={{
                        maskImage: `url('/images/svgicons/xlsx.svg')`,
                        transform: 'translateY(1px)',
                      }}
                    >
                      xlsx
                    </div>
                    匯出 xlsx
                  </Button>
                </li>
                <li>
                  <Button className="btn-text">
                    <div
                      className="svgIcon"
                      style={{
                        maskImage: `url('/images/svgicons/pdf.svg')`,
                        transform: 'translateY(1px)',
                      }}
                    >
                      pdf
                    </div>
                    匯出 pdf
                  </Button>
                </li>
                <li>
                  <ImportBtn
                    setting={{
                      className: 'btn-text mb-0',
                      currentID,
                    }}
                  />
                </li>
                <li className="li-dropdown">
                  <DropdownButton
                    title={
                      <div
                        className="align-items-center  d-flex"
                        style={{ height: '30px' }}
                      >
                        <div
                          className="svgIcon settingIcon bg-primary"
                          style={{
                            maskImage: `url('/images/svgicons/setting.svg')`,
                          }}
                        >
                          設定
                        </div>
                      </div>
                    }
                  >
                    <div className=" toggle-wrap shadow-block">
                      <ul className="toggle-wrap-nav">
                        <li className="d-block ms-0">
                          <Button
                            className="btn-text"
                            onClick={() => openRecord()}
                          >
                            <Image src="/images/svgicons/clock.svg" alt="" />
                            版本紀錄
                          </Button>
                        </li>
                      </ul>
                    </div>
                  </DropdownButton>
                </li>
              </ul>
            </div>
            <div className="module-setting">
              <Button
                className="btn-primary"
                onClick={() => {
                  openBoard()
                }}
              >
                <Image
                  src="/images/svgicons/board.svg"
                  className="mx-3"
                  alt=""
                />
                更換模板
              </Button>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  )
}

ToolBar.propTypes = {
  setting: PropTypes.shape().isRequired,
}

export default ToolBar
