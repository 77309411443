import React from 'react'
import PropTypes from 'prop-types'
import { Image, Button } from 'react-bootstrap'

function Board({ setting }) {
  const {
    closeBoard = () => {},
    boards = [],
    boardStyle,
    switchBoard,
  } = setting

  return (
    <div className="rightSidenav">
      <div className="sidenav-header">
        <Image src="/images/svgicons/board-title.svg" alt="" />
        更換模板
        <Button className="btn-text close-btn" onClick={() => closeBoard()}>
          <div
            className="svgIcon"
            style={{
              maskImage: `url('/images/svgicons/close.svg')`,
            }}
          >
            關閉
          </div>
        </Button>
      </div>
      <div className="scroll-sidebar">
        {boards.map((item, itemIndex) => (
          <div
            className="record-item bg-transparent"
            key={`record-item${itemIndex}`}
          >
            <Button
              className="d-block btn-board"
              active={boardStyle === item.key}
              onClick={() => switchBoard(item.key)}
            >
              <div className="img">
                <Image src={item.image} className="w-100 d-block" alt="" />
              </div>
              {item.title}
            </Button>
          </div>
        ))}
      </div>
    </div>
  )
}

Board.propTypes = {
  setting: PropTypes.shape().isRequired,
}

export default Board
