import React, {
  useState,
  useEffect,
  useMemo,
  useContext,
  useCallback,
} from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Button, Image, Form, InputGroup } from 'react-bootstrap'
import { DraftContext } from './ContextProvider'
import EditText from './EditText'
import HeaderAccount from './HeaderAccount'

// const LogoImg = '/images/dai_vinci_logo.svg'

function Header() {
  const location = useLocation()
  const isPageHeader = useMemo(
    () =>
      [
        '/',
        '/login',
        '/register',
        '/edit_password',
        '/forget_password',
      ].includes(location.pathname),
    [location]
  )

  if (isPageHeader) {
    return <IndexHeader />
  }

  return <TopNav />
}

function IndexHeader() {
  const location = useLocation()
  const [showMenu, setShowMenu] = useState(false)
  const [navStyle, setNavStyle] = useState('light')

  const navigate = useNavigate()
  const handleLogin = async () => {
    navigate('/list')
  }

  useEffect(() => {
    if (
      location.pathname === '/login' ||
      location.pathname === '/register' ||
      location.pathname === '/edit_password' ||
      location.pathname === '/forget_password'
    ) {
      setNavStyle('dark')
    }
  }, [location.pathname])

  const [offset, setOffset] = useState(0)
  useEffect(() => {
    const onScroll = () => setOffset(window.scrollY)

    // clean up code
    window.removeEventListener('scroll', onScroll)
    window.addEventListener('scroll', onScroll, { passive: true })
    return () => window.removeEventListener('scroll', onScroll)
  }, [])

  return (
    <div
      id="header"
      className={`fixed ${navStyle} ${
        offset > window.innerHeight ? 'dark' : ''
      }`}
    >
      <nav className="navbar navbar-expand-lg">
        <h1>
          <a className="navbar-brand" href="/">
            AI設計策略生成工具
          </a>
        </h1>
        <div
          className={`collapse navbar-collapse ${showMenu ? 'show' : ''}`}
          id="mainmenu"
        >
          <ul className="navbar-nav">
            <li className="nav-item">
              <a
                className="nav-link"
                href="https://www.rdlab.tw/"
                target="_blank"
                rel="noopener noreferrer"
                title="前往Design R&D Lab website(另開新視窗)"
              >
                Design R&D Lab
              </a>
            </li>
            <li className="nav-item">
              <button
                type="button"
                className="nav-link"
                onClick={handleLogin}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' || e.key === ' ') {
                    handleLogin(e)
                  }
                }}
              >
                專案列表
              </button>
            </li>
          </ul>
        </div>
        <div
          className="account"
          onClick={handleLogin}
          role="button"
          tabIndex="0"
          onKeyDown={(e) => {
            if (e.key === 'Enter' || e.key === ' ') {
              handleLogin(e)
            }
          }}
        >
          <div className="account-avatar">T</div>
          <span>帳號</span>
        </div>
        <Button
          className="navbar-toggler"
          aria-controls="mainmenu"
          aria-expanded="false"
          aria-label="Toggle navigation"
          onClick={() => {
            setShowMenu(!showMenu)
          }}
        >
          <span className="navbar-toggler-icon"> </span>
        </Button>
      </nav>
    </div>
  )
}

function TopNav() {
  const { draft: module, setDraft } = useContext(DraftContext)
  const setModule = (data) => {
    setDraft({ module: data })
  }
  const [tempTitle, setTempTitle] = useState('未命名專案(1)')
  const location = useLocation()
  const isPorject = useMemo(
    () =>
      [
        '/module1',
        '/module2/product',
        '/module2/matrix',
        '/module2/weak',
        '/module2/design',
        '/module2/inovation',
        '/module3',
        '/module4',
        '/create_module',
      ].includes(location.pathname),
    [location]
  )

  const isCreate = useMemo(
    () => ['/create_module', '/custom_module'].includes(location.pathname),
    [location]
  )

  const [offset, setOffset] = useState(0)
  const onScroll = useCallback(
    (e) => {
      const window = e.currentTarget
      if (offset > window.scrollY) {
        // setDirection('up')
        document.getElementById('wrapper').classList.remove('scrolldown')
      } else if (offset < window.scrollY) {
        // setDirection('down')
        document.getElementById('wrapper').classList.add('scrolldown')
      }
      setOffset(window.scrollY)
    },
    [offset]
  )

  useEffect(() => {
    setOffset(window.scrollY)

    window.removeEventListener('scroll', onScroll)
    window.addEventListener('scroll', onScroll, { passive: true })
    return () => window.removeEventListener('scroll', onScroll)
  }, [onScroll])

  const handleInputChange = (value) => {
    if (module.draft_id) {
      setModule({
        ...module,
        title: value,
      })
    } else {
      setTempTitle(value)
    }
  }

  return (
    <div id="header" className="fixed pageNav">
      <nav className="navbar navbar-expand-lg">
        {isPorject ? (
          <EditText
            setting={{
              title: module.draft_id ? module.title : tempTitle,
              handleInputChange,
              bgColor: '#FFFFFF',
            }}
          />
        ) : (
          <h1>
            <a className="navbar-brand" href="/">
              AI設計策略生成工具
            </a>
          </h1>
        )}

        <div className="d-flex flex-wrap">
          {!isCreate && !isPorject && <Search />}
          <HeaderAccount />
        </div>
      </nav>
    </div>
  )
}

function Search() {
  return (
    <Form className="search-form">
      <InputGroup>
        <Form.Control
          name="keyword"
          type="text"
          placeholder="搜尋專案"
          className="search-input"
          aria-label="search"
        />
        <InputGroup.Text className="search-btn">
          <Button variant="">
            <Image src="/images/svgicons/search.svg" alt="" />
          </Button>
        </InputGroup.Text>
      </InputGroup>
    </Form>
  )
}

export default Header
