/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { Label, TextArea } from '../../tools'

function DesignCardColumn({ setting }) {
  const { content } = setting
  const [cards, setCards] = useState([])

  useEffect(() => {
    const opportunities = content.map((item) => ({
      headline: item.opportunities_headline,
      content: item.opportunities,
    }))
    const challenges = content.map((item) => ({
      headline: item.challenges_headline,
      content: item.challenges,
    }))
    const data = [
      { title: '設計機會', data: opportunities },
      { title: '設計挑戰', data: challenges },
    ]

    setCards(data)
  }, [content])

  return (
    <>
      {cards.map((item, index) => (
        <div className="design-card-column">
          <div className="card-wrapper">
            <div className="module-control">
              <div className="card-item-list" key={index}>
                <div className="box-title">{item.title}</div>
                {item.data.map((itemContent, contentIndex) => (
                  <CardItem
                    key={contentIndex}
                    setting={{
                      itemContent,
                    }}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  )
}

function CardItem({ setting }) {
  const { itemContent } = setting
  const [itemLoading, setItemLoading] = useState(true)

  useEffect(() => {
    if (itemLoading) {
      setTimeout(() => {
        setItemLoading(false)
      }, 1000)
    }
  }, [itemLoading])

  return (
    <div className="inner position-relative">
      <div className="">
        {itemLoading && <Loading setting={{ style: 'one' }} />}
        <div className={`fade ${itemLoading ? '' : 'show'}`}>
          <Label
            setting={{
              title: itemContent.headline,

              edit: true,
            }}
          />
        </div>
      </div>
      <div className="">
        {itemLoading && <Loading setting={{ style: 'muti' }} />}
        <div className={`fade ${itemLoading ? '' : 'show'}`}>
          <TextArea
            setting={{
              value: itemContent.content,
            }}
          />
        </div>
      </div>
    </div>
  )
}

function Loading({ setting }) {
  const { style } = setting

  if (style === 'one') {
    return (
      <div className="loading-wrap">
        <div className="loading-bar">loading</div>
      </div>
    )
  }

  return (
    <div className="loading-wrap">
      <div className="loading-bar mb-2">loading</div>
      <div className="loading-bar mb-4">loading</div>
      <div className="loading-bar mb-2">loading</div>
      <div className="loading-bar mb-4">loading</div>
      <div className="loading-bar mb-2">loading</div>
      <div className="loading-bar">loading</div>
    </div>
  )
}

Loading.propTypes = {
  setting: PropTypes.shape().isRequired,
}

CardItem.propTypes = {
  setting: PropTypes.shape().isRequired,
}

DesignCardColumn.propTypes = {
  setting: PropTypes.shape().isRequired,
}

export default DesignCardColumn
