import React, { useState, useEffect, useContext } from 'react'
import { v4 as uuid } from 'uuid'
import { DraftContext } from '../components/ContextProvider'
import { EditWrapper } from '../components'
import SuggestCard from '../components/SuggestCard'
import { activeInput, initMultipleDataFromJson } from '../lib/utility'
import { usePestelStore } from '../store'

function Module1() {
  const { draft } = useContext(DraftContext)
  const [module] = useState(
    draft.draft_id && draft
      ? draft
      : { module1: { category: [{ id: uuid(), name: '', list: {} }] } }
  )
  // const [records, setRecords] = useState([])
  const [boardStyle, setBoardStyle] = useState('card')

  const { politic, economic, social, technology, environment, law, setField } =
    usePestelStore((store) => ({
      politic: store.politic,
      economic: store.economic,
      social: store.social,
      technology: store.technology,
      environment: store.environment,
      law: store.law,
      setField: store.setField,
    }))

  const boards = [
    {
      id: 1,
      title: '卡片式',
      image: '/images/boards/board-card.svg',
      key: 'card',
    },
    {
      id: 2,
      title: '列表式',
      image: '/images/boards/board-list.svg',
      key: 'list',
    },
  ]

  const switchBoard = (board) => {
    setBoardStyle(board)
  }

  // useEffect(() => {
  //   setRecords(module.module1.history && module.module1.history)
  // }, [module])

  useEffect(() => {
    activeInput()
    const json_urls = [
      {
        url: 'data/pestel/politic.json',
        dataName: 'politic',
      },
      {
        url: 'data/pestel/economic.json',
        dataName: 'economic',
      },
      {
        url: 'data/pestel/social.json',
        dataName: 'social',
      },
      {
        url: 'data/pestel/technology.json',
        dataName: 'technology',
      },
      {
        url: 'data/pestel/environment.json',
        dataName: 'environment',
      },
      {
        url: 'data/pestel/law.json',
        dataName: 'law',
      },
    ]

    initMultipleDataFromJson(json_urls, setField)
  }, [])

  // const [tab] = useState(0)

  return (
    <EditWrapper
      setting={{
        showInput: module.show_input,
        moduleTitle: module.title,
        moduleKeyword: module.module1.keyword,
        // records,
        boards,
        boardStyle,
        switchBoard,
      }}
    >
      <div className="tab-panel">
        <SuggestCard
          setting={{
            module: [politic, economic, social, technology, environment, law],
            boardStyle,
          }}
        />
      </div>
    </EditWrapper>
  )
}

export default Module1
