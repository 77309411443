import { create } from 'zustand'

const usePersonaStore = create((set) => ({
  user_info: {},
  quote: {},
  health_advocate_profile: {},
  setField: (field, value) => set((state) => ({ ...state, [field]: value })),
}))

export default usePersonaStore
