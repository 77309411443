/* eslint-disable no-nested-ternary */
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar as solidStar } from '@fortawesome/free-solid-svg-icons'
import { faStar as regularStar } from '@fortawesome/free-regular-svg-icons'

function Star({ setting }) {
  const { star } = setting
  const [hoverRate, setHoverRate] = useState(0)

  return (
    <div className="stars">
      推薦度：
      {[1, 2, 3, 4, 5].map((rate) => (
        <FontAwesomeIcon
          key={rate}
          icon={
            hoverRate > 0
              ? hoverRate >= rate
                ? solidStar
                : regularStar
              : parseInt(star, 10) >= rate
              ? solidStar
              : regularStar
          }
          className="star-icon"
          onMouseEnter={() => setHoverRate(rate)}
          onMouseLeave={() => setHoverRate(0)}
          title="刪除"
        />
      ))}
    </div>
  )
}

Star.propTypes = {
  setting: PropTypes.shape().isRequired,
}

export default Star
