/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import ImgCard from './ImgCard'
import TextArea from './TextArea'

function PageHeader({ setting }) {
  // const { label, labelID, content = [], edit, handleInputChange } = setting
  const { content = [], edit } = setting
  const [cards, setCards] = useState(content)

  useEffect(() => {
    // handleInputChange(label, labelID, {
    //   id: labelID,
    //   type: 'header',
    //   content: cards,
    // })
  }, [cards])

  const handleTrash = (labelId) => {
    setCards(cards.filter((item, l) => l !== labelId))
  }

  return (
    <div className="info-header">
      <div className="info-header-img">
        <ImgCard setting={{ img: content[0] }} />
      </div>
      <div className="info-header-info flex-1">
        {content[1] && (
          <div className={`inner middle ${edit && 'p-0'}`}>
            {edit ? (
              <TextArea
                setting={{
                  value: content[1],
                  labelId: 1,
                  handleTrash,
                }}
              />
            ) : (
              <div
                className=""
                dangerouslySetInnerHTML={{ __html: content[1] }}
              />
            )}
          </div>
        )}
      </div>
    </div>
  )
}

PageHeader.propTypes = {
  setting: PropTypes.shape().isRequired,
}

export default PageHeader
