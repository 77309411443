import React from 'react'
import { Button } from 'react-bootstrap'
import PropTypes from 'prop-types'

function CardTool({ setting }) {
  const {
    handleAdd = () => {},
    handleRegenerate = () => {},
    handleDelete = () => {},
    onlyRegenerate = false,
  } = setting

  return (
    <>
      {!onlyRegenerate && (
        <>
          <Button
            className="btn-text"
            onClick={() => {
              handleAdd()
            }}
          >
            <div
              className="svgIcon"
              style={{
                maskImage: `url('/images/svgicons/add.svg')`,
              }}
            >
              新增
            </div>
          </Button>
          <hr />
        </>
      )}
      <Button
        className="btn-text"
        onClick={() => {
          handleRegenerate()
        }}
      >
        <div
          className="svgIcon"
          style={{
            maskImage: `url('/images/svgicons/generate.svg')`,
          }}
        >
          重新生成
        </div>
      </Button>
      {!onlyRegenerate && (
        <>
          <hr />
          <Button
            className="btn-text"
            onClick={() => {
              handleDelete()
            }}
          >
            <div
              className="svgIcon"
              style={{
                maskImage: `url('/images/svgicons/trash.svg')`,
              }}
            >
              刪除
            </div>
          </Button>
        </>
      )}
    </>
  )
}
CardTool.propTypes = {
  setting: PropTypes.shape().isRequired,
}
export default CardTool
