import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Image, Form, DropdownButton } from 'react-bootstrap'

function ImportBtn({ setting }) {
  const { className = 'btn btn-primary btn-lg', currentID } = setting
  const [isUpload, setIsUpload] = useState(false)
  const [uploadFile, setUploadFile] = useState('')
  const getModuleText = (id) => {
    switch (id) {
      case 1:
        return 'PESTEL'
      case 2:
        return '競品分析'
      case 3:
        return '顧客旅程'
      case 4:
        return '人物誌'
      default:
        return 'PESTEL'
    }
  }

  const [currentModuleText] = useState(getModuleText(currentID))

  return (
    <DropdownButton
      align="start"
      title={
        <div className={className}>
          <div
            className="svgIcon"
            style={{
              maskImage: `url('/images/svgicons/import2.svg')`,
              marginRight: '10px',
            }}
          >
            import
          </div>
          匯入{currentModuleText}
        </div>
      }
    >
      <div className="toggle-wrap shadow-block import-wrap">
        <Form className="">
          <strong className="toggle-wrap-title">從電腦上傳檔案</strong>
          <p>
            上傳你的照片、文件。系統會將這些內容轉換成可完全自訂的格式，供你編輯、重新設計及共同製作。
          </p>
          <div className="drag-area">
            <Form.Label htmlFor="uploadfile" className="w-100">
              <div className={`notUpload ${isUpload && 'd-none'}`}>
                <Image
                  src="/images/drag-area-img.png"
                  className="img-fluid file-img"
                  alt=""
                />
                <p className="text-center">將檔案拖放至此或</p>
              </div>
              <div className={`isUpload ${!isUpload && 'd-none'}`}>
                <strong>{uploadFile}</strong>
              </div>
              <div className="btn btn-primary btn-md">
                <div
                  className="svgIcon"
                  style={{
                    maskImage: `url('/images/svgicons/import2.svg')`,
                    transform: 'translateY(1px)',
                  }}
                >
                  icon
                </div>
                {isUpload ? '重新上傳' : '選擇檔案'}
              </div>
            </Form.Label>
            <Form.Control
              id="uploadfile"
              name="uploadfile"
              type="file"
              onChange={(e) => {
                if (e.target.value !== '') {
                  setUploadFile(e.target.files[0].name)
                  setIsUpload(true)
                }
              }}
            />
          </div>
        </Form>
      </div>
    </DropdownButton>
  )
}

ImportBtn.propTypes = {
  setting: PropTypes.shape().isRequired,
}

export default ImportBtn
