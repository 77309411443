import React, { useContext } from 'react'
import { Button } from 'react-bootstrap'
import { DraftContext } from './ContextProvider'

function SidebarClose() {
  const { closeSidebar } = useContext(DraftContext)

  return (
    <div className="sidebar-close">
      <Button
        className="btn-text"
        onClick={() => {
          closeSidebar()
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 13 96"
          width="13"
          height="96"
          fill="#262D34"
        >
          <path d="M0,0 h1 c0,20,12,12,12,32 v32 c0,20,-12,12,-12,32 H0 z" />
          <path d="M0.5,0 c0,20,12,12,12,32 v32 c0,20,-12,12,-12,32" />
          <g style={{ transform: 'translateY(40px) scale(0.6)' }}>
            <path
              d="M15.7 0.3C15.3 -0.1 14.7 -0.1 14.3 0.3L8 6.6L1.7 0.3C1.3 -0.1 0.7 -0.1 0.3 0.3C-0.1 0.7 -0.1 1.3 0.3 1.7L6.6 8L0.3 14.3C-0.1 14.7 -0.1 15.3 0.3 15.7C0.5 15.9 0.7 16 1 16C1.3 16 1.5 15.9 1.7 15.7L8 9.4L14.3 15.7C14.5 15.9 14.8 16 15 16C15.2 16 15.5 15.9 15.7 15.7C16.1 15.3 16.1 14.7 15.7 14.3L9.4 8L15.7 1.7C16.1 1.3 16.1 0.7 15.7 0.3Z"
              fill="#FFFFFF"
            />
          </g>
        </svg>
      </Button>
    </div>
  )
}

export default SidebarClose
