/* eslint-disable no-promise-executor-return */
import React, { useState, useEffect, useRef } from 'react'
import moment from 'moment'
import { Row, Col, Image } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMinus, faPaperPlane } from '@fortawesome/free-solid-svg-icons'
import Searchbar from './SearchBar'
// import { SocketContext } from './ContextProvider'

function GPTHelper() {
  const [show, setShow] = useState(false)
  const [generating, setgenerating] = useState(false)
  const [chats, setChats] = useState([])
  // const [gptMessage, setGptMessage] = useState('')

  // scroll to bottom every new chat
  const ref = useRef(null)
  useEffect(() => {
    ref.current?.scrollIntoView({ behavior: 'smooth' })
  }, [chats, ref.current])

  const chatIcon = '/images/chat-icon.png'
  const nl2br = (str) =>
    str.split('\n').map((item, index) => (
      <React.Fragment key={index}>
        {item}
        <br />
      </React.Fragment>
    ))
  useEffect(() => {
    if (!generating)
      setChats((prevState) => [
        ...prevState,
        {
          setting: {
            chat: 'Hi 我是機器人，這是範例回答',
            from: 'gpt',
          },
        },
      ])
  }, [generating])
  const sendMessage = async (text) => {
    if (text.trim() === '') return
    setChats((prevState) => [
      ...prevState,
      {
        setting: {
          chat: text,
          from: 'user',
        },
      },
    ])
    setgenerating(true)
    const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms))
    await delay(1000)
    setgenerating(false)
  }

  return (
    <div
      className="position-fixed  d-flex flex-column justify-content-end px-5 py-4"
      style={{
        maxHeight: '75%',
        right: 0,
        bottom: 0,
        zIndex: '9999',
        pointerEvents: show ? 'auto' : 'none',
      }}
    >
      <Row
        className="message-item border d-flex flex-column bg-light"
        style={{
          visibility: show ? 'visible' : 'hidden',
        }}
      >
        <div className="d-flex position-relative message-header">
          <h2 className="mx-auto fw-bolder fs-7 text-dai">dAI 小幫手</h2>
          <div
            className="position-absolute fs-7"
            style={{
              top: '50%',
              right: '5.5%',
              cursor: 'pointer',
              transform: 'translateY(-50%)',
            }}
            onClick={() => setShow(!show)}
            aria-hidden
          >
            <FontAwesomeIcon icon={faMinus} title="收合" />
          </div>
        </div>
        <div className="message-body">
          <div className="inner  bg-white rounded  d-flex flex-column px-3">
            {(chats.map ? chats : []).map(({ setting, created_on }, index) => {
              const { chat, from } = setting
              const chat_id = `chat-${index} `

              return (
                <Row
                  key={chat_id}
                  className={
                    from === 'gpt'
                      ? 'w-100 d-flex justify-content-start mx-0 px-1 py-3'
                      : 'w-100 d-flex justify-content-end mx-0 px-1 py-3'
                  }
                >
                  {from === 'gpt' && (
                    <Col xs={1} className="px-0">
                      <Image
                        src={chatIcon}
                        style={{
                          zIndex: '555',
                          height: '4rem',
                          width: 'auto',
                        }}
                        className="ms-auto mt-auto fs-1 text-dai-light"
                        // onClick={() => setShow(!show)}
                        title={show ? '隱 藏' : 'dAI 小 幫 手'}
                      />
                    </Col>
                  )}
                  <Col
                    xs={from === 'gpt' ? '11' : 'auto'}
                    className="d-flex flex-column justify-content-center"
                  >
                    <div
                      className={`${
                        from === 'gpt'
                          ? 'gpt-chat'
                          : 'user-chat bg-primary text-white'
                      }`}
                    >
                      {nl2br(chat)}
                    </div>
                    <div className="text-end created">
                      {moment(created_on).format('MM/DD hh:mm a')}
                    </div>
                  </Col>
                </Row>
              )
            })}
            {generating && (
              <Row className="w-100 h-25 d-flex justify-content-start mx-0 px-1 py-3">
                <Col xs={1} className="px-0">
                  <Image
                    src={chatIcon}
                    style={{
                      zIndex: '555',
                      height: '4rem',
                      width: 'auto',
                    }}
                    className="ms-auto mt-auto fs-1 text-dai-light"
                    // onClick={() => setShow(!show)}
                    title={show ? '隱 藏' : 'dAI 小 幫 手'}
                  />
                </Col>

                <Col
                  xs={11}
                  className="d-flex flex-column justify-content-center"
                >
                  <div className="border rounded px-2 py-1 text-start">...</div>
                </Col>
              </Row>
            )}
            <div ref={ref} />
          </div>
          <div className="px-0 message-bottom">
            <Searchbar
              setting={{
                method: sendMessage,
                action: <FontAwesomeIcon icon={faPaperPlane} />,
              }}
            />
          </div>
        </div>
      </Row>
      <Image
        src={chatIcon}
        className="p-0 text-dai-light chat-icon"
        onClick={() => setShow(!show)}
        title={show ? '隱 藏' : 'dAI 小 幫 手'}
      />
    </div>
  )
}

export default GPTHelper
