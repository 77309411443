/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd'
import { Row } from 'react-bootstrap'
import { DraftContext } from '../components/ContextProvider'
import DragBar from '../components/DragBar'
import Label from './Label'
import TextArea from './TextArea'
import CardTool from '../components/CardTool'

function Card({ setting }) {
  const {
    label,
    content = [],
    labelEdit = () => {},
    // textEdit = () => {},
    addItem = () => {},
    removeItem = () => {},
    hideToolbar = true,
    regenerateItem = () => {},
    cardHandle = () => {},
  } = setting
  const [cards, setCards] = useState([])

  useEffect(() => {
    setCards(content)
  }, [content])

  const handleCardResort = (startIndex, endIndex) => {
    const result = Array.from(cards)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)
    setCards(result)

    // 回傳結果給前頁
    cardHandle(label, result)
  }

  const onDragEnd = (result) => {
    if (!result.destination) {
      return
    }

    handleCardResort(result.source.index, result.destination.index)
  }

  const textEdit = (value, itemIndex) => {
    setCards(
      cards.map((item, index) =>
        index === itemIndex ? [item[0], value] : item
      )
    )
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="card-drop" direction="horizontal">
        {(dropProvided) => (
          <Row
            {...dropProvided.droppableProps}
            ref={dropProvided.innerRef}
            className="card-item-list"
          >
            {cards.map((item, itemIndex) => (
              <Draggable
                key={`item-${itemIndex}`}
                draggableId={`draggable-${itemIndex}`}
                index={itemIndex}
              >
                {(dragProvided) => (
                  <div
                    ref={dragProvided.innerRef}
                    {...dragProvided.draggableProps}
                    {...dragProvided.dragHandleProps}
                    className="col column-item has-drag pb-5"
                  >
                    <DragBar />
                    <CardItem
                      setting={{
                        label,
                        item,
                        itemIndex,
                        addItem,
                        removeItem,
                        count: cards.length,
                        labelEdit,
                        textEdit,
                        hideToolbar,
                        regenerateItem,
                      }}
                    />
                  </div>
                )}
              </Draggable>
            ))}
            {dropProvided.placeholder}
          </Row>
        )}
      </Droppable>
    </DragDropContext>
  )
}

function CardItem({ setting }) {
  const {
    label,
    item,
    itemIndex,
    addItem = () => {},
    removeItem = () => {},
    labelEdit = () => {},
    textEdit = () => {},
    regenerateItem = () => {},
    count,
    hideToolbar = false,
  } = setting
  const { showWarn, setShowWarn, showGen, setShowGen } =
    useContext(DraftContext)

  // loading
  const [labelLoading, setLabelLoading] = useState(true)
  const [contentLoading, setContentLoading] = useState(true)

  useEffect(() => {
    if (labelLoading) {
      setTimeout(() => {
        setLabelLoading(false)
      }, 1000)
    }

    if (contentLoading) {
      setTimeout(() => {
        setContentLoading(false)
      }, 1000)
    }
  }, [labelLoading, contentLoading])

  const handleAdd = () => {
    if (count < 4) {
      setShowGen({
        ...showGen,
        show: true,
        func: () => {
          addItem(label)
        },
        placeholder: '例: 市場需求',
      })
    }
  }

  // 執行重新生成
  const handleRegenerate = () => {
    setContentLoading(true)
    regenerateItem(label, itemIndex)
  }

  const handleDelete = () => {
    if (count > 1) {
      setShowWarn({
        ...showWarn,
        show: true,
        text: '確定刪除卡片？',
        func: () => {
          removeItem(label, itemIndex)
        },
      })
    }

    if (count === 1) {
      setShowWarn({
        ...showWarn,
        show: true,
        text: '最少一筆',
        func: () => {},
      })
    }
  }
  return (
    <>
      {hideToolbar && (
        <div className="card-tool">
          <CardTool
            setting={{
              handleAdd,
              handleRegenerate,
              handleDelete,
            }}
          />
        </div>
      )}
      <div className="inner">
        <div className="card-label shadow-block">
          {labelLoading && <Loading setting={{ style: 'one' }} />}
          <div className={`fade ${labelLoading ? '' : 'show'}`}>
            <Label
              setting={{
                label,
                title: item.pestel_sub,
                itemIndex,
                labelEdit,
                edit: true,
              }}
            />
          </div>
        </div>
        <div className="card-item shadow-block p-0">
          {contentLoading && <Loading setting={{ style: 'muti' }} />}
          <div className={`fade ${contentLoading ? '' : 'show'}`}>
            <TextArea
              setting={{
                label,
                value: item.content,
                itemIndex,
                textEdit,
              }}
            />
          </div>
        </div>
      </div>
    </>
  )
}

function Loading({ setting }) {
  const { style } = setting

  if (style === 'one') {
    return (
      <div className="loading-wrap">
        <div className="loading-bar">loading</div>
      </div>
    )
  }

  return (
    <div className="loading-wrap">
      <div className="loading-bar mb-2">loading</div>
      <div className="loading-bar mb-4">loading</div>
      <div className="loading-bar mb-2">loading</div>
      <div className="loading-bar mb-4">loading</div>
      <div className="loading-bar mb-2">loading</div>
      <div className="loading-bar">loading</div>
    </div>
  )
}

Loading.propTypes = {
  setting: PropTypes.shape().isRequired,
}

CardItem.propTypes = {
  setting: PropTypes.shape().isRequired,
}

Card.propTypes = {
  setting: PropTypes.shape().isRequired,
}

export default Card
