import React, { useEffect, useContext, useMemo, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'
import { DraftContext } from './ContextProvider'
import Header from './Header'
import Footer from './Footer'
import Warn from './Warn'
import RegenerateModal from './RegenerateModal'
import GPTHelper from './GPTHelper'

function AppWrapper({ children }) {
  const navigate = useNavigate()
  const location = useLocation()
  const { draftId, showWarn, setShowWarn, showGen, setShowGen, currentID } =
    useContext(DraftContext)
  const [placeholder, setPlaceholder] = useState('')
  const [file, setFile] = useState(null)
  useEffect(() => {
    switch (currentID) {
      case 1:
      case 2:
        setPlaceholder('我想分析 [市場] 的 [產業/服務]')
        break
      case 3:
      case 4:
        setPlaceholder(
          '經常 [描述行為特徵] 的 [角色/職業]，想要 [達成什麼目標/任務]'
        )
        break
      default:
    }
  }, [currentID])
  const isInDraft = useMemo(
    () =>
      [
        '/module1',
        '/module2/product',
        '/module2/matrix',
        '/module2/design',
        '/module2/weak',
        '/module2/inovation',
        '/module3',
        '/module4',
        '/Module1',
        '/Module3',
        '/Module4',
      ].includes(location.pathname),
    [location]
  )

  const unGpt = useMemo(
    () =>
      ['login', '/', 'register', 'edit_password', 'forget_password'].indexOf(
        location.pathname
      ) > -1,
    [location.pathname]
  )

  const handleChange = (e) => {
    setFile(e)
  }

  useEffect(() => {
    if (draftId && !isInDraft) {
      if (draftId === 4) {
        navigate('/module3')
      } else if (draftId === 5) {
        navigate('/module4')
      } else {
        navigate('/module1')
      }
    }

    if (!draftId && isInDraft) {
      navigate('/list')
    }
  }, [draftId])

  const isEdit = useMemo(
    () => ['/module1'].includes(location.pathname),
    [location]
  )

  return (
    <div id="wrapper">
      {!isEdit && <Header />}
      <div id="main">{children}</div>
      {!isEdit && <Footer />}
      <Warn
        setting={{
          show: showWarn.show,
          text: showWarn.text,
          icon: '',
          handleConfirm: () => {
            showWarn.func()
          },
          handleClose: () => {
            setShowWarn({ ...showWarn, show: false })
          },
        }}
      />
      <RegenerateModal
        setting={{
          show: showGen.show,
          handleConfirm: () => {
            showGen.func()
          },
          handleClose: () => {
            setShowGen({
              ...showGen,
              show: false,
              isToolBarBtn: false,
              titleLabel: '',
              placeholder: '',
            })
          },
          placeholder: showGen?.isToolBarBtn
            ? placeholder
            : showGen?.placeholder,
          handleChange,
          file,
          titleLabel: showGen?.titleLabel || '分析面向',
          isToolBarBtn: showGen?.isToolBarBtn || false,
        }}
      />
      {!unGpt && <GPTHelper setting={{}} />}
    </div>
  )
}

AppWrapper.propTypes = {
  children: PropTypes.shape().isRequired,
}

export default AppWrapper
