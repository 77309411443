import React from 'react'
import PropTypes from 'prop-types'
import ImgCard from './ImgCard'

function Gallery({ setting }) {
  const { imgs = [], handleOpen } = setting

  return (
    <div className="imgs-row">
      {imgs.map((pic, itemIndex) => (
        <ImgCard key={`img-${itemIndex}`} setting={{ img: pic, handleOpen }} />
      ))}
    </div>
  )
}

Gallery.propTypes = {
  setting: PropTypes.shape().isRequired,
}

export default Gallery
