/* eslint-disable no-promise-executor-return */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd'
import { Form, Image } from 'react-bootstrap'
import { DraftContext } from '../../components/ContextProvider'
import AutoHeightText from '../../tools/AutoHeightText'
import CardTool from '../../components/CardTool'

function MatrixTable({ setting }) {
  const {
    module,
    onDragEnd = () => {},
    addItem = () => {},
    textEdit = () => {},
    deleteCard = () => {},
    priceType,
    setPriceType = () => {},
  } = setting

  const dragItemStyle = (isDragging, draggableStyle) => ({
    userSelect: 'none',
    border: isDragging ? '2px solid #3F75ED' : 'none',
    ...draggableStyle,
  })
  const handlePriceType = (e) => {
    setPriceType(e.target.value)
  }
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div className="edit-block">
        <div className="row-header d-table w-100">
          <div className="row-list">
            <div className="row-column">品牌名稱</div>
            <div className="row-column">
              <div className="d-flex text-nowrap gap-2 align-items-center justify-content-center">
                價格區間
                <Form.Control
                  name="price"
                  type="text"
                  placeholder="幣別"
                  defaultValue={priceType}
                  className="text-start"
                  style={{ width: '80px' }}
                  onChange={(e) => {
                    handlePriceType(e)
                  }}
                />
              </div>
            </div>
            <div className="row-column">主要客群</div>
          </div>
        </div>
        <div className="table-body">
          <Droppable droppableId="rowDrag">
            {(cprovided) => (
              <div className="stage-section" ref={cprovided.innerRef}>
                {module.map((item, index) => (
                  <Draggable key={item.id} draggableId={item.id} index={index}>
                    {(dragProvided, dragSnapshot) => (
                      <div
                        className="column-item table-column dragrow has-drag"
                        ref={dragProvided.innerRef}
                        {...dragProvided.draggableProps}
                        style={{
                          ...dragItemStyle(
                            dragSnapshot.isDragging,
                            dragProvided.draggableProps.style
                          ),
                          minWidth: '100px',
                        }}
                        key={`row${item.id}`}
                      >
                        <div
                          className="drag-bar"
                          {...dragProvided.dragHandleProps}
                        >
                          <div className="module-control-btn">
                            <Image src="/images/toolicons/drag.svg" alt="" />
                          </div>
                        </div>
                        <div className="row-list inner">
                          <Table
                            setting={{
                              content: item,
                              labelID: item.id,
                              deleteCard,
                              cardIndex: index,
                              textEdit,
                              addItem,
                            }}
                          />
                        </div>
                      </div>
                    )}
                  </Draggable>
                ))}
                {cprovided.placeholder}
              </div>
            )}
          </Droppable>
        </div>
      </div>
    </DragDropContext>
  )
}

function Table({ setting }) {
  const {
    labelID,
    content = [],
    addItem = () => {},
    deleteCard = () => {},
    textEdit = () => {},
  } = setting

  const { showWarn, setShowWarn, showGen, setShowGen } =
    useContext(DraftContext)
  const [itemLoading, setItemLoading] = useState(true)
  useEffect(() => {
    if (itemLoading) {
      setTimeout(() => {
        setItemLoading(false)
      }, 1000)
    }
  }, [itemLoading])

  const handleAdd = () => {
    setShowGen({
      ...showGen,
      show: true,
      func: () => {
        addItem(labelID)
      },
      titleLabel: '品牌名稱',
      placeholder: '例: Gogoro',
    })
  }

  const handleRegenerate = () => {
    setItemLoading(true)
  }

  const handleDelete = () => {
    setShowWarn({
      ...showWarn,
      show: true,
      text: '確定移除列表？',
      func: () => {
        deleteCard(labelID)
      },
    })
  }

  return (
    <div className="column-item table-column">
      <div className="card-tool pos-left">
        <CardTool
          setting={{
            handleAdd,
            handleRegenerate,
            handleDelete,
          }}
        />
      </div>
      <div className="row-list inner">
        <div data-title="品牌名稱" className="row-column data-title first-col">
          {itemLoading && <Loading />}
          <div className={itemLoading ? 'd-none' : ''}>
            <Form.Control
              name="brand_name"
              type="text"
              placeholder="品牌名稱"
              defaultValue={content.brand_name}
              className="text-center"
              onChange={(e) => {
                textEdit(labelID, e.target.value, 'brand_name')
              }}
            />
          </div>
        </div>
        <div data-title="價格區間" className="row-column data-title first-col">
          {itemLoading && <Loading setting={{ style: 'one' }} />}
          <div
            className={`d-flex align-items-center ${
              itemLoading ? 'd-none' : ''
            }`}
          >
            <Form.Control
              name="title"
              type="text"
              placeholder="價格"
              defaultValue={content.price_banding_min}
              value={content.price_banding_min}
              onChange={(e) => {
                textEdit(labelID, e.target.value, 'price_banding_min')
              }}
            />
            <span className="px-2">~</span>
            <Form.Control
              name="title"
              type="text"
              placeholder="價格"
              defaultValue={content.price_banding_max}
              value={content.price_banding_max}
              onChange={(e) => {
                textEdit(labelID, e.target.value, 'price_banding_max')
              }}
            />
          </div>
        </div>
        <div data-title="主要客群" className="row-column data-title">
          {itemLoading && <Loading setting={{ style: 'one' }} />}
          <div className={`text-justify ${itemLoading ? 'd-none' : ''}`}>
            <AutoHeightText
              setting={{
                onChange: (value) => {
                  textEdit(labelID, value, 'audiences')
                },
                value: content.audiences,
                placeholder: '主要客群',
                className: '',
              }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

function Loading() {
  return (
    <div
      className="loading-wrap"
      style={{
        position: 'static',
      }}
    >
      <div className="loading-bar">loading</div>
    </div>
  )
}

Table.propTypes = {
  setting: PropTypes.shape().isRequired,
}

MatrixTable.propTypes = {
  setting: PropTypes.shape().isRequired,
}

export default MatrixTable
