import { create } from 'zustand'

const useInovationStore = create((set) => ({
  product_innovation: [],
  service_innovation: [],
  business_model: [],
  infra_develop: [],
  tab_list: ['產品創新', '服務創新', '商業模式', '環境建設'],
  setField: (field, value) => set((state) => ({ ...state, [field]: value })),
}))

export default useInovationStore
