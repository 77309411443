import React, { useContext, useState } from 'react'
import { Form, Image } from 'react-bootstrap'
import { LoadingButton } from '../components'
import { ToastContext } from '../components/ContextProvider'

function ForgetPassword() {
  const { setToast } = useContext(ToastContext)
  const [data, setData] = useState({
    email: '',
  })

  const onDataChange = (event) => {
    setData({ ...data, [event.target.name]: event.target.value })
  }

  const handleLogin = async () => {
    setToast({ show: true, text: '信件已寄出' })
  }

  const fields = [
    {
      label: '帳號',
      type: 'text',
      name: 'email',
      placeholder: '帳號',
    },
  ]

  return (
    <div className="page-center">
      <div className="block">
        <div className="d-flex flex-wrap align-items-center">
          <div className="left">
            <Image src="/images/d_logo_full.png" className="dai-logo" alt="" />
          </div>
          <div className="right">
            <Form>
              <h2>忘記密碼</h2>
              <p>請輸入註冊使用之email</p>
              {fields.map((field) => (
                <Form.Group key={field.name} className="form-group">
                  <Form.Control
                    className="border"
                    name={field.name}
                    type={field.type}
                    placeholder={field.placeholder}
                    onChange={onDataChange}
                  />
                </Form.Group>
              ))}
              <hr className="line" />
              <div className="form-group">
                <LoadingButton
                  className="w-100"
                  variant="primary"
                  btnText="確定送出"
                  disabled={!data.email}
                  onClick={handleLogin}
                />
              </div>
              <div className="form-footer">
                已有帳號？
                <a href="/login" className="">
                  登入
                </a>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ForgetPassword
