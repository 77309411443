import React, { useState, useContext } from 'react'
import PropTypes from 'prop-types'
import { Form, Button, Modal, Image } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faTrashCan,
  faRotateLeft,
  faDownload,
  faArrowUpRightFromSquare,
} from '@fortawesome/free-solid-svg-icons'
import { DraftContext } from './ContextProvider'

function Media() {
  const [imgs, setImgs] = useState([
    'https://picsum.photos/1920/1080?random=1',
    'https://picsum.photos/1920/1080?random=2',
    'https://picsum.photos/1920/1080?random=3',
  ])
  const [trashs, setTrashs] = useState([])
  const moveToTrash = (img) => {
    setImgs(imgs.filter((item) => item !== img))
    setTrashs((array) => [img, ...array])
  }

  const moveToImgs = (img) => {
    setTrashs(trashs.filter((item) => item !== img))
    setImgs((array) => [img, ...array])
  }

  // image modal window
  const [modalImg, setModalImg] = useState()
  const [show, setShow] = useState(false)
  const changeModalImg = (img) => {
    setModalImg(img)
  }

  const handleOpen = () => {
    setShow(true)
  }

  const handleClose = () => {
    setShow(false)
  }

  return (
    <>
      <div className="mediaWrap d-flex flex-wrap">
        <h2 className="modal-title">素材庫</h2>
        <div className="flex-1">
          <div className="mediaHeader">
            <Form.Select aria-label="Default select example">
              <option>全部素材</option>
              <option value="1">素材一</option>
              <option value="2">素材二</option>
              <option value="3">素材三</option>
            </Form.Select>
            <Form.Label htmlFor="uploadfile" className="mb-0">
              <div className="btn btn-primary btn-md">
                <div
                  className="svgIcon"
                  style={{
                    maskImage: `url('/images/svgicons/add.svg')`,
                    transform: 'translateY(1px)',
                    marginRight: '10px',
                  }}
                >
                  icon
                </div>
                上傳素材
              </div>
            </Form.Label>
            <Form.Control
              id="uploadfile"
              name="uploadfile"
              type="file"
              onChange={() => {}}
            />
            <hr className="w-100" />
          </div>
          <div className="mediaList">
            {imgs.map((img, index) => (
              <ImgItem
                key={`img${index}`}
                setting={{
                  img,
                  changeModalImg,
                  moveToTrash,
                  handleOpen,
                }}
              />
            ))}
          </div>
        </div>
      </div>
      <div className="mediaWrap d-flex flex-wrap">
        <h2 className="modal-title w-100">垃圾桶</h2>
        {trashs.length === 0 ? (
          <p style={{ paddingLeft: '20px' }}>
            垃圾桶中沒有任何項目，30天後，系統將會自動系統將會自動刪除
          </p>
        ) : (
          <div className="mediaList px-0">
            {trashs.map((img, index) => (
              <TrashItem
                key={`trash${index}`}
                setting={{
                  img,
                  changeModalImg,
                  moveToImgs,
                  handleOpen,
                }}
              />
            ))}
          </div>
        )}
      </div>
      <ImageModal
        setting={{
          show,
          handleClose,
          img: modalImg,
        }}
      />
    </>
  )
}

function ImgItem({ setting }) {
  const { showWarn, setShowWarn } = useContext(DraftContext)
  const {
    img,
    moveToTrash = () => {},
    handleOpen = () => {},
    changeModalImg = () => {},
  } = setting

  return (
    <div
      className="media-item img-card"
      onClick={() => {
        changeModalImg(img)
        handleOpen()
      }}
      onKeyDown={() => {
        changeModalImg(img)
        handleOpen()
      }}
      role="button"
      tabIndex={0}
    >
      <div className="img-mask">
        <Button
          variant="btn-dai text-light m-auto"
          title="移到垃圾桶"
          onClick={(e) => {
            setShowWarn({
              ...showWarn,
              show: true,
              text: '將圖片移到垃圾桶？',
              func: () => {
                moveToTrash(img)
              },
            })
            e.stopPropagation()
          }}
        >
          <FontAwesomeIcon icon={faTrashCan} />
        </Button>
      </div>
      <img src={img} className="w-100" alt="" />
    </div>
  )
}

function TrashItem({ setting }) {
  const { showWarn, setShowWarn } = useContext(DraftContext)
  const {
    img,
    moveToImgs = () => {},
    handleOpen = () => {},
    changeModalImg = () => {},
  } = setting

  return (
    <div
      className="media-item img-card"
      onClick={() => {
        changeModalImg(img)
        handleOpen()
      }}
      onKeyDown={() => {
        changeModalImg(img)
        handleOpen()
      }}
      role="button"
      tabIndex={0}
    >
      <div className="img-mask">
        <Button
          variant="btn-dai text-light m-auto"
          title="回復"
          onClick={(e) => {
            setShowWarn({
              ...showWarn,
              show: true,
              text: '將圖片移到素材庫？',
              func: () => {
                moveToImgs(img)
              },
            })
            e.stopPropagation()
          }}
        >
          <FontAwesomeIcon icon={faRotateLeft} />
        </Button>
      </div>
      <img src={img} className="w-100" alt="" />
    </div>
  )
}

function ImageModal({ setting }) {
  const { show, handleClose, img } = setting
  return (
    <Modal
      size="xl"
      show={show !== false}
      onHide={() => handleClose(false)}
      className="warning-block img-modal"
      centered
    >
      <Image src={img} alt="" className="w-100" />
      <div className="d-flex justify-content-end">
        <Button
          title="下載"
          variant="btn-dai"
          href={img}
          download
          onClick={(e) => {
            e.stopPropagation()
          }}
        >
          <FontAwesomeIcon icon={faDownload} />
          下載
        </Button>
        <Button
          title="另開視窗"
          variant="btn-dai"
          href={img}
          target="_blank"
          onClick={(e) => {
            e.stopPropagation()
          }}
        >
          <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
          另開視窗
        </Button>
      </div>
      <Button className="btn-text modal-close" onClick={() => handleClose()}>
        <div
          className="svgIcon"
          style={{
            maskImage: `url('/images/svgicons/close.svg')`,
          }}
        >
          close
        </div>
      </Button>
    </Modal>
  )
}

ImageModal.propTypes = {
  setting: PropTypes.shape().isRequired,
}

ImgItem.propTypes = {
  setting: PropTypes.shape().isRequired,
}

TrashItem.propTypes = {
  setting: PropTypes.shape().isRequired,
}

export default Media
