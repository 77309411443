import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Form, InputGroup, Image } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import { LoadingButton } from '../components'

function Login() {
  const [reveal, setReveal] = useState(false)
  const navigate = useNavigate()
  const handleLogin = async () => {
    navigate('/list')
  }
  const fields = [
    {
      label: '帳號',
      type: 'text',
      name: 'email',
      placeholder: '帳號',
    },
    {
      label: '密碼',
      type: 'password',
      name: 'password',
      placeholder: '密碼',
    },
  ]

  return (
    <div className="page-center">
      <div className="block">
        <div className="d-flex flex-wrap align-items-center">
          <div className="left">
            <Image src="/images/d_logo_full.png" className="dai-logo" alt="" />
          </div>
          <div className="right">
            <Form>
              <h2>登入 AI設計策略生成工具</h2>
              {fields.map((field) => (
                <Form.Group key={field.name} className="form-group">
                  {field.type === 'password' ? (
                    <InputGroup className="">
                      <Form.Control
                        className="border"
                        name={field.name}
                        type={reveal ? 'text' : field.type}
                        placeholder={field.placeholder}
                      />
                      <InputGroup.Text>
                        <FontAwesomeIcon
                          title={reveal ? '點擊以隱藏密碼' : '點擊以顯示密碼'}
                          icon={reveal ? faEye : faEyeSlash}
                          onClick={() => setReveal(!reveal)}
                        />
                      </InputGroup.Text>
                    </InputGroup>
                  ) : (
                    <Form.Control
                      className="border"
                      name={field.name}
                      type={reveal ? 'text' : field.type}
                      onKeyDown={() => {}}
                      placeholder={field.placeholder}
                    />
                  )}
                </Form.Group>
              ))}
              <hr className="line" />
              <div className="form-group">
                <LoadingButton
                  className="w-100"
                  variant="primary"
                  btnText="登入"
                  onClick={handleLogin}
                />
              </div>
              <div className="form-footer">
                <a href="/register" className="">
                  建立帳戶
                </a>
                <a href="/forget_password" className="">
                  忘記密碼
                </a>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Login
