import React, { useState, useEffect, useContext } from 'react'
import { EditWrapper } from '../../components'
import { DraftContext } from '../../components/ContextProvider'
import SuggestDesign from './SuggestDesign'
import Subnav from './Subnav'
import { useDesignStore } from '../../store'
import { initMultipleDataFromJson } from '../../lib/utility'

function Product() {
  const { draft } = useContext(DraftContext)
  const [module] = useState(draft.draft_id && draft ? draft : {})

  // const [tab] = useState(0)
  // const [records, setRecords] = useState([])
  // temp record
  const [records] = useState([])
  const [boardStyle, setBoardStyle] = useState('card')
  const boards = [
    {
      id: 1,
      title: '設計機會及挑戰橫式',
      image: '/images/boards/board-card.svg',
      key: 'card',
    },
    {
      id: 2,
      title: '設計機會及挑戰直式',
      image: '/images/boards/board-list.svg',
      key: 'list',
    },
  ]

  const switchBoard = (board) => {
    setBoardStyle(board)
  }

  // useEffect(() => {
  //   if (module.module2) {
  //     setRecords(module.module2.design.history && module.module2.design.history)
  //   }
  // }, [module])

  const { opportunities_and_challenges, setField } = useDesignStore(
    (store) => ({
      opportunities_and_challenges: store.opportunities_and_challenges,
      setField: store.setField,
    })
  )

  useEffect(() => {
    const json_urls = [
      {
        url: '/data/competitive_product_analysis/design.json',
        dataName: 'opportunities_and_challenges',
        target: '2-3_opportunities-and-challenges',
      },
    ]

    initMultipleDataFromJson(json_urls, setField)
  }, [])

  return (
    module.module2 && (
      <EditWrapper
        setting={{
          showInput: module.show_input,
          moduleTitle: module.title,
          moduleKeyword: module.module2.keyword,
          records,
          boards,
          boardStyle,
          switchBoard,
        }}
      >
        <>
          <Subnav setting={{ currentID: 3 }} />
          <div className="tab-panel">
            <SuggestDesign
              setting={{
                module: opportunities_and_challenges,
                boardStyle,
              }}
            />
          </div>
        </>
      </EditWrapper>
    )
  )
}

export default Product
