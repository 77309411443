import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'

function Subnav({ setting }) {
  const { currentID = 1 } = setting
  const navigate = useNavigate()
  const [subNav] = useState([
    {
      id: 1,
      title: '競品分析表',
      href: '/module2/product',
    },
    {
      id: 2,
      title: '產品分析矩陣',
      href: '/module2/matrix',
    },
    {
      id: 3,
      title: '設計機會及挑戰',
      href: '/module2/design',
    },
    {
      id: 4,
      title: '創新機會方案',
      href: '/module2/inovation',
    },
  ])

  return (
    <ul className="subnav">
      {subNav.map((nav, navIndex) => (
        <li key={`subnav${navIndex}`}>
          <button
            type="button"
            className={`sidebar-submenu btn btn-primary ${
              nav.id === currentID && 'active'
            }`}
            onClick={() => {
              navigate(nav.href)
            }}
          >
            {nav.title}
          </button>
        </li>
      ))}
    </ul>
  )
}

Subnav.propTypes = {
  setting: PropTypes.shape().isRequired,
}

export default Subnav
