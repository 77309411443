import React from 'react'
import PropTypes from 'prop-types'
import { Image, Button, Modal, Form } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'

function RegenerateModal({ setting }) {
  const {
    show = false,
    handleClose,
    handleConfirm,
    placeholder = '',
    handleChange,
    file,
    isToolBarBtn = false,
    titleLabel = '分析面向',
  } = setting

  const handleDownload = () => {
    if (!(file instanceof Blob)) {
      console.error('The provided file is not a Blob instance')
      return
    }

    const reader = new FileReader()
    // 用 FileReader 讀取檔案
    reader.readAsDataURL(file)
    reader.onload = () => {
      const url = reader.result
      const a = document.createElement('a')
      a.href = url
      a.download = file.name
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
    }
    reader.onerror = (error) => {
      console.error('Error reading file:', error)
    }
  }
  return (
    <Modal
      size="xl"
      show={show !== false}
      onHide={() => handleClose(false)}
      className="warning-block"
      centered
    >
      <Modal.Body className="">
        <h2 className="text-center mb-5">
          {isToolBarBtn ? '請輸入提示語' : `請輸入${titleLabel}`}
        </h2>
        <Form>
          <div className="create-group">
            {isToolBarBtn && (
              <>
                <Form.Label className="mb-0 file-input" htmlFor="file">
                  <Image src="/images/svgicons/keyword.svg" alt="" />
                </Form.Label>
                <Form.Control
                  type="file"
                  id="file"
                  name="file"
                  defaultValue={null}
                  className="d-none"
                  onChange={(e) => {
                    handleChange(e.target.files[0])
                  }}
                />
              </>
            )}
            <Form.Control
              id="keyword"
              name="keyword"
              type="text"
              placeholder={placeholder}
              className="border-0 flex-1 mx-0"
              aria-label=""
              defaultValue=""
            />
            <Button
              className="btn btn-primary btn-md"
              onClick={() => {
                handleConfirm()
                handleClose()
              }}
            >
              <div
                className="svgIcon bg-white mx-2"
                style={{
                  maskImage: `url('/images/svgicons/status.svg')`,
                  transform: 'translateY(1px)',
                }}
              >
                icon
              </div>
              {isToolBarBtn ? '重新生成' : '局部生成'}
            </Button>
          </div>
          {file && (
            <div className="d-flex justify-content-center mt-4">
              <button
                type="button"
                onClick={handleDownload}
                className="btn-link btn border-0"
              >
                {file.name}
              </button>
              <button
                type="button"
                onClick={() => handleChange(null)}
                className="btn px-0"
              >
                <FontAwesomeIcon icon={faTimes} />
              </button>
            </div>
          )}
        </Form>
      </Modal.Body>
      <Button className="btn-text modal-close" onClick={() => handleClose()}>
        <div
          className="svgIcon"
          style={{
            maskImage: `url('/images/svgicons/close.svg')`,
          }}
        >
          close
        </div>
      </Button>
    </Modal>
  )
}

RegenerateModal.propTypes = {
  setting: PropTypes.shape().isRequired,
}

export default RegenerateModal
