import React from 'react'
import PropTypes from 'prop-types'

function Loading({ setting }) {
  const { style } = setting

  if (style === 'one') {
    return (
      <div className="loading-wrap">
        <div className="loading-bar">loading</div>
      </div>
    )
  }

  return (
    <div className="loading-wrap">
      <div className="loading-bar mb-2">loading</div>
      <div className="loading-bar mb-4">loading</div>
      <div className="loading-bar mb-2">loading</div>
      <div className="loading-bar mb-4">loading</div>
      <div className="loading-bar mb-2">loading</div>
      <div className="loading-bar">loading</div>
    </div>
  )
}

Loading.propTypes = {
  setting: PropTypes.shape().isRequired,
}
export default Loading
