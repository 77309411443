// utility.js

export const activeInput = () => {
  const inputs = document.querySelectorAll(
    '.column-item input, .column-item textarea'
  )

  inputs.forEach((input) => {
    input.addEventListener('focus', () => {
      let parent = input.parentElement
      while (parent) {
        if (
          parent.classList.contains('column-item') ||
          parent.classList.contains('has-drag')
        ) {
          parent.classList.add('unfocus')
        }
        parent = parent.parentElement
      }
    })

    input.addEventListener('blur', () => {
      let parent = input.parentElement
      while (parent) {
        if (
          parent.classList.contains('column-item') ||
          parent.classList.contains('has-drag')
        ) {
          parent.classList.remove('unfocus')
        }
        parent = parent.parentElement
      }
    })
  })
}

export const initMultipleDataFromJson = async (json_urls, setField) => {
  try {
    const fetchPromises = json_urls.map(async (url) => {
      const response = await fetch(url.url)
      if (!response.ok) {
        throw new Error(`Network response was not ok for ${url}`)
      }
      const contentType = response.headers.get('content-type')
      if (!contentType || !contentType.includes('application/json')) {
        throw new TypeError(`Received non-JSON response from ${url}`)
      }
      return response.json()
    })

    const dataArray = await Promise.all(fetchPromises)

    dataArray.forEach((data, index) => {
      const fieldName = json_urls[index].dataName
      if (json_urls[index].target) {
        setField(fieldName, data[json_urls[index].target])
      } else {
        setField(fieldName, data)
      }
    })
  } catch (error) {
    console.error('Failed to fetch data:', error)
  }
}
