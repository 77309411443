import React from 'react'
import PropTypes from 'prop-types'
import { Label, TextArea } from '../tools'

function StoryCard({ setting }) {
  const { label, text } = setting

  return (
    <div className="col">
      <div className="inner shadow-block">
        {label && (
          <Label
            setting={{
              label: '',
              title: label,
              edit: true,
            }}
          />
        )}
        <div style={{ marginLeft: '-20px', marginRight: '-20px' }}>
          <TextArea
            setting={{
              label: '',
              value: text,
            }}
          />
        </div>
      </div>
    </div>
  )
}

StoryCard.propTypes = {
  setting: PropTypes.shape().isRequired,
}

export default StoryCard
