import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button } from 'react-bootstrap'
import { DraftContext } from '../components/ContextProvider'
import { CreateModule } from '../components'

function Create() {
  const { setDraftId } = useContext(DraftContext)
  const navigate = useNavigate()

  return (
    <div className="content">
      <div>
        <Button
          className="btn-text return-btn"
          onClick={() => {
            setDraftId(0)
            navigate('/list')
          }}
        >
          <span
            className="svgIcon"
            style={{
              maskImage: `url('/images/svgicons/return.svg')`,
            }}
          >
            return
          </span>
          返回專案列表
        </Button>
      </div>
      <CreateModule setting={{ mode: 'create' }} />
    </div>
  )
}

export default Create
