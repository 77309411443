import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd'
import { SectionTitle, Star, Text, DragGallery, Loading } from '../../tools'
import DragBar from '../../components/DragBar'
import CardTool from '../../components/CardTool'

function InovationRow({ setting }) {
  const { module, handleList, list_index } = setting
  const moduleLength = Object.keys(module).length
  const [isLoadingArr, setIsLoadingArr] = useState(
    Array(moduleLength).fill(false)
  )
  const handleRegenerate = (index) => {
    setIsLoadingArr(isLoadingArr.map((item, i) => (i === index ? true : item)))
    setTimeout(() => {
      setIsLoadingArr(
        isLoadingArr.map((item, i) => (i === index ? false : item))
      )
    }, 1000)
  }
  const onDragEnd = (result) => {
    const { source, destination } = result
    if (!destination) {
      return
    }

    handleList(source, destination, list_index)
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId={`card-drop-${list_index}`} direction="horizontal">
        {(dropProvided) => (
          <div
            // eslint-disable-next-line
            {...dropProvided.droppableProps}
            ref={dropProvided.innerRef}
            className="card-item-list row"
          >
            {module.map((item, index) => (
              <Draggable
                key={`item-${index}`}
                draggableId={`column-${index}`}
                index={index}
              >
                {(dragProvided) => (
                  <div
                    ref={dragProvided.innerRef}
                    // eslint-disable-next-line
                    {...dragProvided.draggableProps}
                    // eslint-disable-next-line
                    {...dragProvided.dragHandleProps}
                    className="stage-section board-column3x   has-drag"
                  >
                    <div className="shadow-block h-100 column-item position-relative">
                      <div
                        className="card-tool"
                        style={{ left: 'auto', right: '10px' }}
                      >
                        <CardTool
                          setting={{
                            handleRegenerate: () => handleRegenerate(index),
                            onlyRegenerate: true,
                          }}
                        />
                      </div>
                      <DragBar />
                      {isLoadingArr[index] ? (
                        <Loading setting={{ style: '' }} />
                      ) : (
                        <div className="color-card-body d-flex flex-column">
                          <SectionTitle
                            setting={{
                              title: `方案${index + 1}`,
                              edit: false,
                            }}
                          />
                          <div className="module-item">
                            <Star
                              setting={{
                                star: item.star,
                              }}
                            />
                          </div>
                          <div className="module-item flex-1">
                            <Text
                              setting={{
                                content: [
                                  `<p><strong>${item.headline}</strong></p><p>${item.ideas}</p>`,
                                ],
                                label: index,
                              }}
                            />
                          </div>
                          <div className="module-item">
                            <DragGallery
                              setting={{
                                imgs: item.images,
                                list_index: index,
                              }}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </Draggable>
            ))}
            <div style={{ height: 0 }}>{dropProvided.placeholder}</div>
          </div>
        )}
      </Droppable>
    </DragDropContext>
  )
}

InovationRow.propTypes = {
  setting: PropTypes.shape().isRequired,
}

export default InovationRow
