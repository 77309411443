/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Label from '../../tools/Label'
import TextArea from '../../tools/TextArea'
import SectionTitle from '../../tools/SectionTitle'
import CardTool from '../../components/CardTool'

function DesignCard({ setting }) {
  const {
    label,
    content = {},
    labelEdit = () => {},
    // textEdit = () => {},
  } = setting
  const [card, setCard] = useState([])

  useEffect(() => {
    setCard(content)
  }, [content])

  const [isLoading, setIsLoading] = useState(false)

  const handleRegenerate = () => {
    setIsLoading(true)
    setTimeout(() => {
      setIsLoading(false)
    }, 1000)
  }

  const textEdit = (value, itemIndex) => {
    console.log(value, itemIndex)
    // setCard(
    //   card.map((item, index) => (index === itemIndex ? [item[0], value] : item))
    // )
  }

  return (
    <div className="col has-drag">
      <div className="color-card position-relative flex-column d-flex">
        <div
          className="card-tool"
          style={{ right: '5%', top: '10%', left: 'auto' }}
        >
          <CardTool
            setting={{
              handleRegenerate,
              onlyRegenerate: true,
            }}
          />
        </div>
        <div className="opportunities">
          <SectionTitle
            setting={{
              title: '設計機會',
              icon: '',
              edit: false,
            }}
          />
          <CardItem
            setting={{
              label,
              headLine: card.opportunities_headline,
              text: card.opportunities,
              labelEdit,
              textEdit,
              handleRegenerate,
              isLoading,
              itemIndex: 0,
            }}
          />
        </div>
        <div>
          <SectionTitle
            setting={{
              title: '設計挑戰',
              icon: '',
              edit: false,
            }}
          />
          <CardItem
            setting={{
              label,
              headLine: card.challenges_headline,
              text: card.challenges,
              labelEdit,
              textEdit,
              handleRegenerate,
              isLoading,
              itemIndex: 1,
            }}
          />
        </div>
      </div>
    </div>
  )
}

function CardItem({ setting }) {
  const {
    label,
    headLine,
    itemIndex,
    labelEdit = () => {},
    textEdit = () => {},
    isLoading,
    text,
  } = setting
  const [itemLoading, setItemLoading] = useState(true)

  useEffect(() => {
    if (itemLoading) {
      setTimeout(() => {
        setItemLoading(false)
      }, 1000)
    }
  }, [itemLoading])

  return (
    <div className="inner d-flex flex-1 flex-column">
      <div className="card-label shadow-block">
        {(itemLoading || isLoading) && <Loading setting={{ style: 'one' }} />}
        <div className={`fade ${itemLoading ? '' : 'show'}`}>
          <Label
            setting={{
              label,
              title: headLine,
              itemIndex,
              labelEdit,
              edit: true,
            }}
          />
        </div>
      </div>
      <div className="card-item shadow-block p-0">
        {(itemLoading || isLoading) && <Loading setting={{ style: 'muti' }} />}
        <div className={`fade ${itemLoading || isLoading ? '' : 'show'}`}>
          <TextArea
            setting={{
              label,
              value: text,
              itemIndex,
              textEdit,
            }}
          />
        </div>
      </div>
    </div>
  )
}

function Loading({ setting }) {
  const { style } = setting

  if (style === 'one') {
    return (
      <div className="loading-wrap">
        <div className="loading-bar">loading</div>
      </div>
    )
  }

  return (
    <div className="loading-wrap">
      <div className="loading-bar mb-2">loading</div>
      <div className="loading-bar mb-4">loading</div>
      <div className="loading-bar mb-2">loading</div>
      <div className="loading-bar mb-4">loading</div>
      <div className="loading-bar mb-2">loading</div>
      <div className="loading-bar">loading</div>
    </div>
  )
}

Loading.propTypes = {
  setting: PropTypes.shape().isRequired,
}

CardItem.propTypes = {
  setting: PropTypes.shape().isRequired,
}

DesignCard.propTypes = {
  setting: PropTypes.shape().isRequired,
}

export default DesignCard
