import React from 'react'
import PropTypes from 'prop-types'
import { Droppable, Draggable } from '@hello-pangea/dnd'
import ImgCard from './ImgCard'

function DragGallery({ setting }) {
  const { imgs, handleOpen, list_index } = setting

  return (
    <Droppable
      droppableId={`img-group-${list_index}`}
      direction="horizontal"
      type={`droppable-${list_index}`}
    >
      {(dropProvided) => (
        <div
          // eslint-disable-next-line
          {...dropProvided.droppableProps}
          ref={dropProvided.innerRef}
          className="imgs-row"
        >
          {imgs.map((pic, itemIndex) => (
            <Draggable
              key={`img-${itemIndex}`}
              draggableId={`img--${list_index}-${itemIndex}`}
              index={itemIndex}
              type={`droppable-${list_index}`}
            >
              {(dragProvided) => (
                <div
                  ref={dragProvided.innerRef}
                  // eslint-disable-next-line
                  {...dragProvided.draggableProps}
                  // eslint-disable-next-line
                  {...dragProvided.dragHandleProps}
                  className="has-drag"
                >
                  <ImgCard
                    key={`img-${itemIndex}`}
                    setting={{ img: pic, handleOpen }}
                  />
                </div>
              )}
            </Draggable>
          ))}
          {dropProvided.placeholder}
        </div>
      )}
    </Droppable>
  )
}

DragGallery.propTypes = {
  setting: PropTypes.shape().isRequired,
}

export default DragGallery
