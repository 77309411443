import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Form } from 'react-bootstrap'

function Label({ setting }) {
  const { label, title, edit, itemIndex, labelEdit = () => {} } = setting
  const [inputValue, setInputValue] = useState('')
  useEffect(() => {
    setInputValue(title)
  }, [title])
  const handleChange = (e) => {
    const { value } = e.target
    setInputValue(value)
    labelEdit(label, itemIndex, value)
  }
  return (
    <div className="color-title">
      {edit ? (
        <Form.Control
          name="title"
          type="text"
          className="border-0 label-title"
          placeholder="標題"
          // defaultValue={title}
          value={inputValue}
          onChange={handleChange}
        />
      ) : (
        title
      )}
    </div>
  )
}

Label.propTypes = {
  setting: PropTypes.shape().isRequired,
}

export default Label
