import React from 'react'
import PropTypes from 'prop-types'

function TableHeader({ setting }) {
  const { list } = setting

  return (
    <div className="row-header d-table w-100">
      <div className="row-list">
        {list.map((m, index) => (
          <div key={`column${index}`} className="row-column">
            {m}
          </div>
        ))}
      </div>
    </div>
  )
}

TableHeader.propTypes = {
  setting: PropTypes.shape().isRequired,
}

export default TableHeader
