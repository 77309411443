/* eslint-disable no-promise-executor-return */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd'
import { Form, Image } from 'react-bootstrap'
import { DraftContext } from '../../components/ContextProvider'
import CardTool from '../../components/CardTool'

function MatrixTableColumn({ setting }) {
  const {
    module,
    onDragEnd = () => {},
    addItem = () => {},
    textEdit = () => {},
    deleteCard = () => {},
  } = setting

  const dragItemStyle = (isDragging, draggableStyle) => ({
    userSelect: 'none',
    border: isDragging ? 'none' : 'none',
    ...draggableStyle,
  })

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div className="edit-block">
        <div className="d-flex">
          <div className="table-column-header">
            <div className="table-column-title" style={{ height: '65px' }}>
              品牌
            </div>
            <div className="table-column-title" style={{ height: '65px' }}>
              價格區間
            </div>
            <div className="table-column-title" style={{ height: '100px' }}>
              主要客群
            </div>
          </div>
          <Droppable droppableId="columnDrag" direction="horizontal">
            {(cprovided) => (
              <div className="table-column-wrap" ref={cprovided.innerRef}>
                <div className="table-column-inner">
                  {module.map((item, index) => (
                    <Draggable
                      key={item.id}
                      draggableId={item.id}
                      index={index}
                    >
                      {(dragProvided, dragSnapshot) => (
                        <div
                          className="table-column-info has-drag mx-0"
                          ref={dragProvided.innerRef}
                          {...dragProvided.draggableProps}
                          style={dragItemStyle(
                            dragSnapshot.isDragging,
                            dragProvided.draggableProps.style
                          )}
                          key={`row${item.id}`}
                        >
                          <div
                            className="drag-bar"
                            {...dragProvided.dragHandleProps}
                          >
                            <div className="module-control-btn">
                              <Image src="/images/toolicons/drag.svg" alt="" />
                            </div>
                          </div>
                          <div className="row-list inner">
                            <Table
                              setting={{
                                content: item,
                                labelID: item.id,
                                deleteCard,
                                cardIndex: index,
                                textEdit,
                                addItem,
                              }}
                            />
                          </div>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {cprovided.placeholder}
                </div>
              </div>
            )}
          </Droppable>
        </div>
      </div>
    </DragDropContext>
  )
}

function Table({ setting }) {
  const {
    labelID,
    content = [],
    // addItem = () => {},
    deleteCard = () => {},
    textEdit = () => {},
  } = setting

  const { showWarn, setShowWarn } = useContext(DraftContext)
  const [itemLoading, setItemLoading] = useState(true)

  const handleAdd = () => {}
  const handleRegenerate = () => {}
  const handleDelete = () => {
    setShowWarn({
      ...showWarn,
      show: true,
      text: '確定移除列表？',
      func: () => {
        deleteCard(labelID)
      },
    })
  }

  useEffect(() => {
    if (itemLoading) {
      setTimeout(() => {
        setItemLoading(false)
      }, 1000)
    }
  }, [itemLoading])

  return (
    <div className="">
      <div className="card-tool pos-left d-none">
        <CardTool setting={{ handleAdd, handleRegenerate, handleDelete }} />
      </div>
      <div className="row-list inner">
        <div
          data-title="品牌名稱"
          className="table-column-box d-flex align-items-center justify-content-center border-0"
          style={{ height: '65px' }}
        >
          {itemLoading && <Loading />}
          <div className={itemLoading ? 'd-none' : ''}>
            <Form.Control
              name="brand_name"
              type="text"
              placeholder="品牌名稱"
              defaultValue={content.brand_name}
              value={content.brand_name}
              className="text-center"
              onChange={(e) => {
                textEdit(labelID, e.target.value, 'brand_name')
              }}
            />
          </div>
          <hr className="colorBar" />
        </div>
        <div
          data-title="價格區間"
          className="table-column-box d-flex align-items-center justify-content-center border-0"
          style={{ height: '65px' }}
        >
          {itemLoading && <Loading setting={{ style: 'one' }} />}
          <div
            className={`d-flex align-items-center ${
              itemLoading ? 'd-none' : ''
            }`}
          >
            <Form.Control
              name="title"
              type="text"
              placeholder="價格"
              defaultValue={content.price_banding_min}
              className="text-end"
              value={content.price_banding_min}
              onChange={(e) => {
                textEdit(labelID, e.target.value, 'price_banding_min')
              }}
            />
            <span className="px-2">~</span>
            <Form.Control
              name="title"
              type="text"
              placeholder="價格"
              defaultValue={content.price_banding_max}
              value={content.price_banding_max}
              onChange={(e) => {
                textEdit(labelID, e.target.value, 'price_banding_max')
              }}
            />
          </div>
        </div>
        <div
          data-title="主要客群"
          className="table-column-box border-0"
          style={{ height: '100px' }}
        >
          {itemLoading && <Loading setting={{ style: 'one' }} />}
          <div className={`text-justify ${itemLoading ? 'd-none' : ''}`}>
            <Form.Control
              name="audience"
              as="textarea"
              placeholder="主要客群"
              defaultValue={content.audiences}
              style={{ height: '100%' }}
              onChange={(e) => {
                textEdit(labelID, e.target.value, 'audiences')
              }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

function Loading() {
  return (
    <div
      className="loading-wrap"
      style={{
        position: 'static',
      }}
    >
      <div className="loading-bar">loading</div>
    </div>
  )
}

Table.propTypes = {
  setting: PropTypes.shape().isRequired,
}

MatrixTableColumn.propTypes = {
  setting: PropTypes.shape().isRequired,
}

export default MatrixTableColumn
