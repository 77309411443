import React, { useState, useEffect, useContext } from 'react'
// import { v4 as uuid } from 'uuid'
import { DraftContext } from '../components/ContextProvider'
import { EditWrapper } from '../components'
import SuggestPersona from '../components/SuggestPersona'
import { useCustomerJourneyStore } from '../store'
import { initMultipleDataFromJson } from '../lib/utility'

function Module3() {
  const { draft } = useContext(DraftContext)
  const [module] = useState(draft.draft_id && draft ? draft : {})
  // const [tab] = useState(0)
  // const [list] = useState(
  //   module.module3
  //     ? module.module3.category.filter((item, i) => i === tab)[0]
  //     : {}
  // )

  const {
    user_info,
    emotions,
    opportunities_and_painpoints,
    journeys,
    setField,
  } = useCustomerJourneyStore((store) => ({
    user_info: store.user_info,
    emotions: store.emotions,
    opportunities_and_painpoints: store.opportunities_and_painpoints,
    journeys: store.journeys,

    setField: store.setField,
  }))

  useEffect(() => {
    const json_urls = [
      {
        url: 'data/customer_journey/user_info.json',
        dataName: 'user_info',
      },
      {
        url: 'data/customer_journey/journeys.json',
        dataName: 'journeys',
      },
      {
        url: 'data/customer_journey/emotions.json',
        dataName: 'emotions',
      },
      {
        url: 'data/customer_journey/opportunities_and_painpoints.json',
        dataName: 'opportunities_and_painpoints',
      },
    ]

    initMultipleDataFromJson(json_urls, setField)
  }, [])
  // temp records
  const [records] = useState([])

  // const [records, setRecords] = useState([])
  // // 設定版本紀錄
  // useEffect(() => {
  //   if (module.module3) {
  //     setRecords(module.module3.history && module.module3.history)
  //   }
  // }, [module])

  return (
    <EditWrapper
      setting={{
        showInput: '',
        moduleTitle: module.title,
        moduleKeyword: '',
        records,
      }}
    >
      <div className="tab-panel">
        <div className="edit-block">
          <SuggestPersona
            setting={{
              user_info,
              emotions: emotions ? emotions.emotions : [],
              opportunities_and_painpoints: opportunities_and_painpoints
                ? opportunities_and_painpoints.opportunities_and_painpoints
                : [],
              journeys: journeys ? journeys.journeys : [],
            }}
          />
        </div>
      </div>
    </EditWrapper>
  )
}

export default Module3
