import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Row } from 'react-bootstrap'
// import { DraftContext } from '../../components/ContextProvider'
import DesignCard from './DesignCard'
import DesignCardColumn from './DesignCardColumn'
import { useDesignStore } from '../../store'

function SuggestDesign({ setting }) {
  const { module = [], boardStyle } = setting
  // const { suggestCardList, setSuggestCardList } = useContext(DraftContext)

  const { setField, opportunities_and_challenges } = useDesignStore(
    (store) => ({
      opportunities_and_challenges: store.opportunities_and_challenges,
      setField: store.setField,
    })
  )

  // undo and redo
  // const {
  //   // cursor,
  //   // setCursor,
  //   // history,
  //   // setHistory,
  //   // recoverTarget,
  //   // setRecoverTarget,
  // } = useContext(DraftContext)

  useEffect(() => {
    // setHistory([module])
    // setCursor(-1)
    // setRecoverTarget(-1)
  }, [])

  // useEffect(() => {
  //   if (recoverTarget !== -1) {
  //     setSuggestCardList(history[recoverTarget])
  //   }
  // }, [recoverTarget])

  // useEffect(() => {
  //   setSuggestCardList(module)

  //   // setIsLoadingList(
  //   //   genIsLoadingList(Object.values(module)[0].cards[0].content.length)
  //   // )
  // }, [module])

  // useEffect(() => {
  //   if (recoverTarget >= 0) {
  //     setCursor(recoverTarget)
  //     setRecoverTarget(-1)
  //   } else {
  //     setHistory((prevState) => [...prevState.slice(0, cursor + 1), module])
  //     setCursor((prevState) => prevState + 1)
  //   }
  // }, [suggestCardList])

  const labelEdit = (label, id, value) => {
    const updatedOpportunitiesAndChallenges = [...opportunities_and_challenges]
    if (id === 0) {
      updatedOpportunitiesAndChallenges[label] = {
        ...updatedOpportunitiesAndChallenges[label],
        opportunities_headline: value,
      }
    } else {
      updatedOpportunitiesAndChallenges[label] = {
        ...updatedOpportunitiesAndChallenges[label],
        challenges_headline: value,
      }
    }

    setField('opportunities_and_challenges', updatedOpportunitiesAndChallenges)
  }

  const textEdit = (label, id, value) => {
    const updatedOpportunitiesAndChallenges = {
      ...opportunities_and_challenges,
    }
    if (id === 0) {
      updatedOpportunitiesAndChallenges[label] = {
        ...updatedOpportunitiesAndChallenges[label],
        opportunities: value,
      }
    } else {
      updatedOpportunitiesAndChallenges[label] = {
        ...updatedOpportunitiesAndChallenges[label],
        challenges: value,
      }
    }
    setField('opportunities_and_challenges', updatedOpportunitiesAndChallenges)
  }
  return (
    <div className="edit-block">
      {boardStyle === 'card' && (
        <div className="row">
          <div className="card-wrapper">
            <div className="module-control design-card">
              <Row className="card-item-list">
                {opportunities_and_challenges.map((content, index) => (
                  <DesignCard
                    setting={{
                      labelID: index,
                      label: index,
                      content,
                      labelEdit,
                      textEdit,
                      cardIndex: index,
                    }}
                  />
                ))}
              </Row>
            </div>
          </div>
        </div>
      )}

      {boardStyle === 'list' && (
        <div className="table-column-wrap">
          <div className="table-column-inner">
            <DesignCardColumn
              setting={{
                content: module,
              }}
            />
          </div>
        </div>
      )}
    </div>
  )
}

SuggestDesign.propTypes = {
  setting: PropTypes.shape().isRequired,
}

export default SuggestDesign
