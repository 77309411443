import React, { useState, useEffect, useContext } from 'react'
// import { v4 as uuid } from 'uuid'
import { DraftContext } from '../components/ContextProvider'
import { EditWrapper } from '../components'
import EditorArea from '../components/EditorArea'
import { usePersonaStore } from '../store'
import { initMultipleDataFromJson } from '../lib/utility'

function Module4() {
  const { draft } = useContext(DraftContext)
  const [module] = useState(draft.draft_id && draft ? draft : {})

  // temp records
  const [records] = useState([])
  // const [records, setRecords] = useState([])

  // useEffect(() => {
  //   if (module.module4) {
  //     setRecords(module.module4.history && module.module4.history)
  //   }
  // }, [module])

  const { user_info, quote, health_advocate_profile, setField } =
    usePersonaStore((store) => ({
      user_info: store.user_info,
      quote: store.quote,
      health_advocate_profile: store.health_advocate_profile,

      setField: store.setField,
    }))

  useEffect(() => {
    const json_urls = [
      {
        url: 'data/persona/user_info.json',
        dataName: 'user_info',
      },
      {
        url: 'data/persona/quote.json',
        dataName: 'quote',
      },
      {
        url: 'data/persona/health_advocate_profile.json',
        dataName: 'health_advocate_profile',
      },
    ]

    initMultipleDataFromJson(json_urls, setField)
  }, [])

  const [tab] = useState(0)

  return (
    module.module4 && (
      <EditWrapper
        setting={{
          showInput: module.show_input,
          moduleTitle: module.title,
          moduleKeyword: module.module4.keyword,
          records,
          showToolbar: true,
        }}
      >
        <div className="tab-panel">
          <EditorArea
            setting={{
              user_info,
              health_advocate_profile,
              quote: quote ? quote.quote : [],
              newList: module.module4.category.filter((item, i) => i === tab)[0]
                .newList,
              showToolbar: true,
            }}
          />
        </div>
      </EditWrapper>
    )
  )
}

export default Module4
