import React from 'react'
import PropTypes from 'prop-types'
import TextArea from './TextArea'

function Text({ setting }) {
  const { content = [] } = setting

  return content.map((item, itemIndex) => (
    <div key={`text-${itemIndex}`} className="text-wrap">
      <TextArea
        setting={{
          value: item,
          labelId: itemIndex,
        }}
      />
    </div>
  ))
}

Text.propTypes = {
  setting: PropTypes.shape().isRequired,
}

export default Text
