import React, { useState, useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import { Form } from 'react-bootstrap'
import { DraftContext } from '../components/ContextProvider'
import AutoHeightText from './AutoHeightText'
import CardTool from '../components/CardTool'

function Table({ setting }) {
  const {
    labelID,
    brand_name = '',
    strengths = [],
    weaknesses = [],
    price_banding_min = '',
    price_banding_max = '',
    features = '',
    audiences = '',
    addItem = () => {},
    deleteCard = () => {},
    setIsLoading = () => {},
    textEdit = () => {},
    arrayEdit = () => {},
    isLoading = true,
  } = setting

  const { showWarn, setShowWarn, showGen, setShowGen } =
    useContext(DraftContext)

  const [itemLoading, setItemLoading] = useState(isLoading)
  const [loadingCount, setLoadingCount] = useState(-1)
  useEffect(() => {
    if (itemLoading) {
      setTimeout(() => {
        setItemLoading(false)
        setIsLoading()
      }, 1000)
    }
  }, [itemLoading])
  useEffect(() => {
    if (loadingCount !== -1) {
      setTimeout(() => {
        setLoadingCount(-1)
      }, 1000)
    }
  }, [loadingCount])

  const handleAdd = () => {
    setShowGen({
      ...showGen,
      show: true,
      func: () => {
        addItem(labelID)
      },
      titleLabel: '品牌名稱',
      placeholder: '例: Gogoro',
    })
  }

  const handleRegenerate = () => {
    setItemLoading(true)
  }

  const handleDelete = () => {
    setShowWarn({
      ...showWarn,
      show: true,
      text: '確定移除列表？',
      func: () => {
        deleteCard(labelID)
      },
    })
  }

  const innerHandleRegenerate = (i) => {
    setShowGen({
      ...showGen,
      show: true,
      func: () => {
        setLoadingCount(i)
      },
    })
  }

  return (
    <div className="column-item table-column">
      <div className="card-tool pos-left intable">
        <CardTool
          setting={{
            handleAdd,
            handleRegenerate,
            handleDelete,
          }}
        />
      </div>
      <div className="row-list inner">
        <div data-title="品牌名稱" className="row-column data-title first-col">
          {itemLoading && <Loading setting={{ style: 'one' }} />}
          <div className={`fade ${itemLoading ? '' : 'show'}`}>
            {/* <div className="d-none">{logo}</div> */}
            <Form.Control
              name="brand_name"
              type="text"
              placeholder="品牌名稱"
              defaultValue={brand_name}
              className="text-center"
              onChange={(e) => {
                textEdit(labelID, e.target.value, 'brand_name')
              }}
            />
          </div>
        </div>
        <div className="row-column width-2x p-0">
          {strengths.map((a, i) => (
            <div className="d-flex flex-wrap column-item " key={`advance${i}`}>
              <div
                onDoubleClick={() => {
                  innerHandleRegenerate(i)
                }}
                tabIndex={0}
                role="button"
                data-title="優勢"
                className="row-column data-title width-3x border-bottom"
              >
                {(itemLoading || loadingCount === i) && (
                  <Loading setting={{ style: 'muti' }} />
                )}
                <div
                  className={`text-justify fade ${
                    itemLoading || loadingCount === i ? '' : 'show'
                  }`}
                >
                  <AutoHeightText
                    setting={{
                      onChange: (value) => {
                        arrayEdit(labelID, value, 'strengths', i)
                      },
                      value: a,
                      placeholder: '優勢',
                    }}
                  />
                </div>
              </div>
              <div
                onDoubleClick={() => {
                  innerHandleRegenerate(i)
                }}
                tabIndex={0}
                role="button"
                data-title="劣勢"
                className="row-column data-title width-3x border-bottom"
              >
                {(itemLoading || loadingCount === i) && (
                  <Loading setting={{ style: 'muti' }} />
                )}
                <div
                  className={`fade ${
                    itemLoading || loadingCount === i ? '' : 'show'
                  }`}
                >
                  <div className="text-justify">
                    <AutoHeightText
                      setting={{
                        onChange: (value) => {
                          arrayEdit(labelID, value, 'weaknesses', i)
                        },
                        value: weaknesses[i],
                        placeholder: '劣勢',
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div data-title="價格區間" className="row-column data-title">
          {itemLoading && <Loading setting={{ style: 'one' }} />}
          {itemLoading ? (
            <div>&nbsp;</div>
          ) : (
            <div className="d-flex align-items-center">
              <Form.Control
                name="price_banding_min"
                type="text"
                placeholder="價格"
                defaultValue={`${price_banding_min}`}
                onChange={(e) => {
                  textEdit(labelID, e.target.value, 'price_banding_min')
                }}
                className="text-end"
              />
              <span className="px-2">~</span>
              <Form.Control
                name="price_banding_max"
                type="text"
                placeholder="價格"
                defaultValue={`${price_banding_max}`}
                onChange={(e) => {
                  textEdit(labelID, e.target.value, 'price_banding_max')
                }}
              />
            </div>
          )}
        </div>
        <div data-title="產品特點" className="row-column data-title">
          {itemLoading && <Loading setting={{ style: 'one' }} />}
          {itemLoading ? (
            <div>&nbsp;</div>
          ) : (
            <div className="text-justify">
              <AutoHeightText
                setting={{
                  onChange: (value) => {
                    textEdit(labelID, value, 'features')
                  },
                  value: features,
                  placeholder: '產品特點',
                }}
              />
            </div>
          )}
        </div>
        <div data-title="主要客群" className="row-column data-title">
          {itemLoading && <Loading setting={{ style: 'one' }} />}
          {itemLoading ? (
            <div>&nbsp;</div>
          ) : (
            <div className="text-justify">
              <AutoHeightText
                setting={{
                  onChange: (value) => {
                    textEdit(labelID, value, 'audiences')
                  },
                  value: audiences,
                  placeholder: '主要客群',
                }}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

function Loading({ setting }) {
  const { style } = setting

  if (style === 'one') {
    return (
      <div
        className="loading-wrap"
        style={{
          position: 'static',
        }}
      >
        <div className="loading-bar">loading</div>
      </div>
    )
  }

  return (
    <div className="loading-wrap">
      <div className="loading-bar mb-2">loading</div>
      <div className="loading-bar mb-4">loading</div>
      <div className="loading-bar mb-2">loading</div>
      <div className="loading-bar">loading</div>
    </div>
  )
}

Loading.propTypes = {
  setting: PropTypes.shape().isRequired,
}

Table.propTypes = {
  setting: PropTypes.shape().isRequired,
}

export default Table
