import React from 'react'
import PropTypes from 'prop-types'
import { Label, TextArea } from '../tools'

function StoryCard({ setting }) {
  const { label, text, className = '', icon = '', itemLoading } = setting

  return (
    <div className="col">
      {className === 'hor-card' && (
        <div className="story-icon">
          <img src={icon} alt="" />
        </div>
      )}
      <div className={`position-relative ${className}`}>
        {itemLoading && <Loading />}
        <div className={`fade ${itemLoading ? '' : 'show'}`}>
          <div style={{ marginLeft: '-20px', marginRight: '-20px' }}>
            {label && (
              <Label
                setting={{
                  label: '',
                  title: label,
                  edit: true,
                }}
              />
            )}
            <TextArea
              setting={{
                label: '',
                value: text,
              }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

function Loading() {
  return (
    <div className="loading-wrap">
      <div className="loading-bar mb-2">loading</div>
      <div className="loading-bar mb-4">loading</div>
      <div className="loading-bar mb-2">loading</div>
      <div className="loading-bar mb-4">loading</div>
      <div className="loading-bar mb-2">loading</div>
      <div className="loading-bar">loading</div>
    </div>
  )
}

StoryCard.propTypes = {
  setting: PropTypes.shape().isRequired,
}

export default StoryCard
