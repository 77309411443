import React, { useState, useContext } from 'react'
import PropTypes from 'prop-types'
import ToolBar from './ToolBar'
import EditText from './EditText'
import HeaderAccount from './HeaderAccount'
import SideBar from './SideBar'
import Records from './Records'
import Board from './Board'
import { DraftContext } from './ContextProvider'

function EditWrapper({ children, setting }) {
  const {
    showInput,
    moduleTitle,
    moduleKeyword,
    records,
    showToolbar = false,
    boards = [],
    boardStyle,
    switchBoard = () => {},
  } = setting
  const [setModalShow] = useState(false)
  const [showRecord, setShowRecord] = useState(false)
  const [showBoard, setShowBoard] = useState(false)

  const handleShow = () => setModalShow(true)

  // 版本紀錄
  const openRecord = () => {
    setShowRecord(true)
    setShowBoard(false)
  }
  const closeRecord = () => setShowRecord(false)

  // 變更面板
  const openBoard = () => {
    setShowRecord(false)
    setShowBoard(true)
  }
  const closeBoard = () => setShowBoard(false)

  return (
    <div className="content edit-content flex-1">
      <EditHeader
        setting={{
          moduleTitle,
        }}
      />
      <SideBar
        setting={{
          showInput,
          handleShow,
          keyword: moduleKeyword,
          records,
        }}
      />
      <div className="tab-group">
        <ToolBar setting={{ openRecord, openBoard }} />
      </div>
      <div id="moduleContent" className="module-content">
        <div className={`module-body ${showToolbar ? 'hasToolbar' : ''}`}>
          {children}
        </div>
      </div>
      {showRecord && <Records setting={{ showRecord, closeRecord }} />}
      {showBoard && (
        <Board setting={{ closeBoard, boards, boardStyle, switchBoard }} />
      )}
    </div>
  )
}

function EditHeader({ setting }) {
  const { setDraft, draft } = useContext(DraftContext)
  const { moduleTitle } = setting
  const handleInputChange = (value) => {
    setDraft({ module: { title: value } }, draft.draft_id)
  }

  return (
    <div id="header" className="fixed pageNav">
      <nav className="navbar navbar-expand-lg">
        <div className="d-flex align-items-center">
          <EditText
            setting={{
              title: draft.title || moduleTitle,
              handleInputChange,
              bgColor: '#FFFFFF',
            }}
          />
        </div>
        <div className="d-flex">
          <HeaderAccount />
        </div>
      </nav>
    </div>
  )
}

EditWrapper.propTypes = {
  children: PropTypes.shape().isRequired,
  setting: PropTypes.shape().isRequired,
}

EditHeader.propTypes = {
  setting: PropTypes.shape().isRequired,
}

export default EditWrapper
