import React from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { AppWrapper, ContextProvider } from './components'
import {
  Home,
  Login,
  Register,
  ForgetPassword,
  EditPassword,
  List,
  Module1,
  Product,
  Matrix,
  Design,
  Weak,
  Inovation,
  Module3,
  Module4,
  CreateModule,
} from './pages'
import './stylesheets/style.scss'
import './stylesheets/print.scss'

function App() {
  return (
    <Router>
      <ContextProvider>
        <AppWrapper>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/list" element={<List />} />
            <Route path="/module1" element={<Module1 />} />
            <Route path="/module2/product" element={<Product />} />
            <Route path="/module2/matrix" element={<Matrix />} />
            <Route path="/module2/design" element={<Design />} />
            <Route path="/module2/weak" element={<Weak />} />
            <Route path="/module2/inovation" element={<Inovation />} />
            <Route path="/module3" element={<Module3 />} />
            <Route path="/module4" element={<Module4 />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/forget_password" element={<ForgetPassword />} />
            <Route path="/edit_password" element={<EditPassword />} />
            <Route path="/create_module" element={<CreateModule />} />
          </Routes>
        </AppWrapper>
      </ContextProvider>
    </Router>
  )
}

export default App
