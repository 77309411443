/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import ParentSize from '@visx/responsive/lib/components/ParentSize'
import { AxisLeft, AxisBottom } from '@visx/axis'
import { scaleLinear } from '@visx/scale'
import { GridRows, GridColumns } from '@visx/grid'
import { Group } from '@visx/group'
import { Drag, raise } from '@visx/drag'
import { Text } from '@visx/text'

function tickComponent() {
  // const { x, y, formattedValue } = props
  const x = -25

  return (
    <g x={0} y={0}>
      <Text y={10} x={x} verticalAnchor="start" className="axisleft-text">
        大
      </Text>
      <Text y={265} x={x} verticalAnchor="start" className="axisleft-text">
        中
      </Text>
      <Text y={520} x={x} verticalAnchor="start" className="axisleft-text">
        小
      </Text>
    </g>
  )
}

function XYDrag({ setting }) {
  const {
    width,
    height = '300',
    products,
    handleInputChange = () => {},
    chartDotChange = () => {},
    priceType,
  } = setting
  const [draggingItems, setDraggingItems] = useState([])
  const margin = { top: 10, right: 60, bottom: 70, left: 30 }
  const xMax = width - margin.right
  const yMax = height - margin.bottom
  const priceScale = useMemo(() => {
    const array = products.reduce((prev, cur) => {
      if (!cur) return prev
      const min = parseInt(cur.price_banding_min, 10)
      const max = parseInt(cur.price_banding_max, 10)
      if (min && max) return [...prev, min, max]
      return prev
    }, [])

    const sorted = Array.from(new Set(array))
      .filter((s) => s && !Number.isNaN(s))
      .sort((a, b) => a - b)

    const getPrice = (p1, p2) => {
      try {
        const price = p2
          ? (parseInt(p1, 10) + parseInt(p2, 10)) / 2
          : parseInt(p1, 10)
        return price || (sorted[0] + sorted[sorted.length - 1]) / 2
      } catch (e) {
        // console.log(e)
        return 0
      }
    }

    setDraggingItems(
      products.map((item, index) => ({
        id: `${item.id}_${index}`,
        name: item.brand_name,
        price: getPrice(item.price_banding_min, item.price_banding_max),
        y: item.target_size,
        index,
      }))
    )

    return scaleLinear({
      domain: [sorted[0] * 0.8 || 0, sorted[sorted.length - 1] * 1.2 || 1000],
    })
  }, [products])

  const scoreScale = useMemo(
    () =>
      scaleLinear({
        range: [yMax, margin.top],
        round: true,
        domain: [0, 5],
      }),
    [yMax]
  )

  const yScale = scaleLinear({
    domain: [0, 5],
  })

  const gridXScale = scaleLinear({
    domain: [0, 2],
  })

  const gridYScale = scaleLinear({
    domain: [0, 2],
  })

  priceScale.range([0, xMax])
  yScale.range([yMax, margin.top])
  gridXScale.range([yMax, margin.top])
  gridYScale.range([margin.left, xMax + 32])

  // 座標轉換客群大小
  const toPos = (v) => {
    const svgH = height - margin.top - margin.bottom
    const percent = svgH / 5

    return 5 - v / percent
  }

  return (
    <svg width={width} height={height}>
      <Group>
        <GridRows
          left={margin.left}
          scale={gridXScale}
          width={xMax}
          height={yMax}
          numTicks={2}
          stroke="#E2E4E7"
        />
        <Group transform="translate(0, 10)">
          <GridColumns
            scale={gridYScale}
            width={xMax}
            height={yMax - margin.top}
            numTicks={2}
            stroke="#E2E4E7"
          />
        </Group>
        <AxisBottom
          // hideAxisLine
          // hideTicks
          label={`價格${priceType}`}
          scale={priceScale}
          numTicks={10}
          stroke="#E2E4E7"
          top={yMax}
          left={margin.left}
          tickLabelProps={{
            fontSize: 12,
          }}
          labelClassName="chatLabel"
        />
        <AxisLeft
          // hideTicks
          left={margin.left}
          scale={yScale}
          tickValues={[0, 1, 2, 3, 4, 5]}
          tickComponent={tickComponent}
          stroke="#E2E4E7"
        />
      </Group>
      <Group>
        {draggingItems.map((circle, i) => (
          <Drag
            key={`drag-${circle.id}`}
            width={width - margin.left - margin.right}
            height={height - margin.top - margin.bottom}
            x={priceScale(circle.price) + margin.left}
            y={scoreScale(circle.y)}
            onDragStart={() => {
              setDraggingItems(raise(draggingItems, i))
            }}
            onDragEnd={({ x, y, dx, dy }) => {
              const array = products.reduce((prev, cur) => {
                const min = parseInt(cur.price_banding_min, 10)
                const max = parseInt(cur.price_banding_max, 10)
                if (!min || !max) return prev
                return [...prev, min, max]
              }, [])

              const sorted = Array.from(new Set(array))
                .filter((s) => s && !Number.isNaN(s))
                .sort((a, b) => a - b)
              const range = [
                sorted[0] * 0.8 || 0,
                sorted[sorted.length - 1] * 1.2 || 1000,
              ]
              const target = products[circle.index]
              const ans = parseInt(
                range[0] +
                  (range[1] - range[0]) *
                    ((x + dx) / (width - margin.left - margin.right)),
                10
              )
              chartDotChange(circle.index, ans - range[0], toPos(y + dy))

              handleInputChange(circle.index, [
                target[0],
                target[1],
                target[2],
                target[3],
                ans,
                ans,
                target[6],
                target[7],
                toPos(y + dy),
              ])
            }}
          >
            {({ dragStart, dragEnd, dragMove, isDragging, x, y, dx, dy }) => (
              <React.Fragment key={`dot-${circle.id}`}>
                <text
                  x={x + 20}
                  y={y + 6}
                  height={100}
                  transform={`translate(${dx}, ${dy})`}
                  fontSize={16}
                  fontWeight={600}
                  color="black"
                >
                  {circle.name}
                </text>
                <circle
                  cx={x}
                  cy={y}
                  r={10}
                  transform={`translate(${dx}, ${dy})`}
                  fill="#002CFF"
                  fillOpacity={1}
                  stroke="none"
                  strokeWidth={0}
                  onMouseMove={dragMove}
                  onMouseUp={dragEnd}
                  onMouseDown={dragStart}
                  onTouchStart={dragStart}
                  onTouchMove={dragMove}
                  onTouchEnd={dragEnd}
                  cursor={isDragging ? 'grabbing' : 'grab'}
                />
              </React.Fragment>
            )}
          </Drag>
        ))}
      </Group>
    </svg>
  )
}

function MatrixEditor({ setting }) {
  const {
    module,
    handleInputChange = () => {},
    priceType,
    chartDotChange,
  } = setting
  return (
    <ParentSize>
      {({ width }) => (
        <XYDrag
          setting={{
            width,
            height: 600,
            products: module,
            handleInputChange,
            priceType,
            chartDotChange,
          }}
        />
      )}
    </ParentSize>
  )
}

MatrixEditor.propTypes = {
  setting: PropTypes.shape().isRequired,
}

XYDrag.propTypes = {
  setting: PropTypes.shape().isRequired,
}

export default MatrixEditor
