import { create } from 'zustand'

const usePestelStore = create((set) => ({
  politic: {},
  economic: {},
  social: {},
  technology: {},
  environment: {},
  law: {},
  setField: (field, value) => set((state) => ({ ...state, [field]: value })),
  updatePestelInfo: (field, id, key, value) =>
    set((state) => ({
      ...state,
      [field]: {
        ...state[field],
        pestel_info: state[field].pestel_info.map((info, index) =>
          index === id ? { ...info, [key]: value } : info
        ),
      },
    })),
  removePestelInfo: (field, id) =>
    set((state) => ({
      ...state,
      [field]: {
        ...state[field],
        pestel_info: state[field].pestel_info.filter(
          (item, index) => index !== id
        ),
      },
    })),
  addPestelInfo: (field, obj) =>
    set((state) => ({
      ...state,
      [field]: {
        ...state[field],
        pestel_info: [...state[field].pestel_info, ...[obj]],
      },
    })),
}))

export default usePestelStore
