/* eslint-disable no-promise-executor-return */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import PropTypes from 'prop-types'
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd'
import { Form, Image } from 'react-bootstrap'
import ImgCard from '../../tools/ImgCard'

function TableColumn({ setting }) {
  const {
    module,
    onDragEnd = () => {},
    // addItem = () => {},
    textEdit = () => {},
    arrayEdit = () => {},
    // deleteCard = () => {},
    // setIsLoading = () => {},
    priceType,
  } = setting

  const dragItemStyle = (isDragging, draggableStyle) => ({
    userSelect: 'none',
    border: isDragging ? '2px solid #3F75ED' : 'none',
    ...draggableStyle,
  })

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div className="edit-block">
        <div className="d-flex">
          <div className="table-column-header">
            <div className="table-column-title" style={{ height: '192px' }}>
              品牌
            </div>
            <div className="table-column-title" style={{ height: '212px' }}>
              優勢
            </div>
            <div className="table-column-title" style={{ height: '212px' }}>
              劣勢
            </div>
            <div className="table-column-title" style={{ height: '43px' }}>
              價格區間
            </div>
            <div className="table-column-title" style={{ height: '72px' }}>
              產品特點
            </div>
            <div className="table-column-title" style={{ height: '50px' }}>
              主要客群
            </div>
          </div>
          <Droppable droppableId="columnDrag" direction="horizontal">
            {(cprovided) => (
              <div className="table-column-wrap" ref={cprovided.innerRef}>
                <div className="table-column-inner">
                  {module.map((column, index) => (
                    <Draggable
                      key={column.id}
                      draggableId={column.id}
                      index={index}
                    >
                      {(dragProvided, dragSnapshot) => (
                        <div
                          className="table-column-info has-drag"
                          ref={dragProvided.innerRef}
                          {...dragProvided.draggableProps}
                          style={dragItemStyle(
                            dragSnapshot.isDragging,
                            dragProvided.draggableProps.style
                          )}
                          key={`column-${index}`}
                        >
                          <div
                            className="drag-bar"
                            {...dragProvided.dragHandleProps}
                          >
                            <div className="module-control-btn">
                              <Image src="/images/toolicons/drag.svg" alt="" />
                            </div>
                          </div>
                          <div
                            className="table-column-box d-flex align-items-center justify-content-center"
                            style={{ height: '192px' }}
                          >
                            <div>
                              {column?.logo && (
                                <div className="img text-center">
                                  <ImgCard setting={{ img: column?.logo }} />
                                </div>
                              )}
                              <Form.Control
                                name="brand_name"
                                type="text"
                                placeholder="品牌名稱"
                                defaultValue={column.brand_name}
                                className="text-center"
                                onChange={(e) => {
                                  textEdit(
                                    column.id,
                                    e.target.value,
                                    'brand_name'
                                  )
                                }}
                              />
                            </div>
                          </div>
                          <div
                            className="table-column-box"
                            style={{ height: '212px' }}
                          >
                            {column.strengths.map((value, j) => (
                              <div key={`advances${j}`}>
                                <Form.Control
                                  name="advances[]"
                                  as="textarea"
                                  placeholder="優勢"
                                  defaultValue={value}
                                  style={{ height: '100%' }}
                                  onChange={(e) => {
                                    arrayEdit(
                                      column.id,
                                      e.target.value,
                                      'strength',
                                      j
                                    )
                                  }}
                                />
                              </div>
                            ))}
                          </div>
                          <div
                            className="table-column-box"
                            style={{ height: '212px' }}
                          >
                            {column.weaknesses.map((value, j) => (
                              <div
                                className="table-column-info"
                                key={`weak${j}`}
                              >
                                <Form.Control
                                  name="weak[]"
                                  as="textarea"
                                  placeholder="劣勢"
                                  defaultValue={value}
                                  style={{ height: '100%' }}
                                  onChange={(e) => {
                                    arrayEdit(
                                      column.id,
                                      e.target.value,
                                      'weaknesses',
                                      j
                                    )
                                  }}
                                />
                              </div>
                            ))}
                          </div>
                          <div
                            className="table-column-box"
                            style={{ height: '43px' }}
                          >
                            <div className="d-flex align-items-center">
                              <Form.Control
                                name="price1"
                                type="text"
                                placeholder="價格"
                                defaultValue={`4priceType ${column.price_banding_min}`}
                                onChange={(e) => {
                                  textEdit(
                                    column.id,
                                    e.target.value,
                                    'price_banding_min'
                                  )
                                }}
                                className="text-end"
                              />
                              <span className="px-2">~</span>
                              <Form.Control
                                name="price2"
                                type="text"
                                placeholder="價格"
                                defaultValue={`${priceType} ${column.price_banding_max}`}
                                onChange={(e) => {
                                  textEdit(
                                    column.id,
                                    e.target.value,
                                    'price_banding_max'
                                  )
                                }}
                              />
                            </div>
                          </div>
                          <div
                            className="table-column-box"
                            style={{ height: '72px' }}
                          >
                            <Form.Control
                              name="sp"
                              as="textarea"
                              placeholder="產品特點"
                              defaultValue={column.features}
                              style={{ height: '100%' }}
                              onChange={(e) => {
                                textEdit(column.id, e.target.value, 'features')
                              }}
                            />
                          </div>
                          <div
                            className="table-column-box"
                            style={{ height: '50px' }}
                          >
                            <Form.Control
                              name="audience"
                              as="textarea"
                              placeholder="主要客群"
                              defaultValue={column.audiences}
                              style={{ height: '100%' }}
                              onChange={(e) => {
                                textEdit(column.id, e.target.value, 'audiences')
                              }}
                            />
                          </div>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {cprovided.placeholder}
                </div>
              </div>
            )}
          </Droppable>
        </div>
      </div>
    </DragDropContext>
  )
}

TableColumn.propTypes = {
  setting: PropTypes.shape().isRequired,
}

export default TableColumn
