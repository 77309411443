import { v4 as uuid } from 'uuid'

const bgArr = ['#F9FBFF', '#E0F7FA', '#FFF3E0'] // 示例背景颜色数组

export const fetchCompetitiveParital = () =>
  fetch(
    '/data/competitive_product_analysis/competitive_partial_generation.json'
  )
    .then((res) => {
      if (!res.ok) {
        throw new Error('Network response was not ok')
      }
      return res.json()
    })
    .then((data) => {
      const newData = {
        ...data,
        id: uuid(),
        bg: bgArr[Math.floor(Math.random() * bgArr.length)],
      }
      return newData
    })
    .catch((error) => {
      console.error('Fetch error:', error)
      return null
    })

export const addItem = async (labelID, competitive, setField) => {
  const index = competitive.findIndex((item) => item.id === labelID)

  const newData = JSON.parse(JSON.stringify(competitive))
  const fetchedData = await fetchCompetitiveParital()

  if (fetchedData) {
    newData.splice(index + 1, 0, fetchedData)
    setField('competitive', newData)
  }
}

export const deleteCard = (labelID, competitive, setField) => {
  let newData = JSON.parse(JSON.stringify(competitive))
  newData = newData.filter((item) => item.id !== labelID)
  setField('competitive', newData)
}

export const textEdit = (id, value, label, competitive, setField) => {
  let newData = JSON.parse(JSON.stringify(competitive))
  newData = newData.map((item) =>
    item.id === id ? { ...item, [label]: value } : item
  )
  setField('competitive', newData)
}

export const arrayEdit = (label, value, type, i, competitive, setField) => {
  const newData = competitive.map((item) => {
    if (item.id !== label) return item

    const updatedItem = { ...item }
    if (type === 'weaknesses') {
      updatedItem.weaknesses = item.weaknesses.map((row, index) =>
        index === i ? value : row
      )
    } else {
      updatedItem.strengths = item.strengths.map((row, index) =>
        index === i ? value : row
      )
    }

    return updatedItem
  })

  setField('competitive', newData)
}

export const initCompetitive = (setField) => {
  fetch('/data/competitive_product_analysis/competitive.json')
    .then((res) => res.json())
    .then((data) => {
      const priceType = data['2-1_competitive'].shift()
      const newData = data['2-1_competitive'].map((innerItem, index) => ({
        ...innerItem,
        id: uuid(),
        bg: bgArr[index % bgArr.length],
      }))
      setField('price_symbol', priceType.price_symbol)
      setField('competitive', newData)
    })
}
