import React from 'react'
// import PropTypes from 'prop-types'
import { Image } from 'react-bootstrap'

function DragBar() {
  return (
    <div className="drag-bar">
      <div className="module-control-btn">
        <Image src="/images/toolicons/drag.svg" alt="" />
      </div>
    </div>
  )
}

// DragBar.propTypes = {
//   setting: PropTypes.shape().isRequired,
// }

export default DragBar
