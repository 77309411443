// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media print {
  .stage-section:after {
    content: "";
    page-break-after: always;
    display: block;
  }
  .print-none, .pageControl, .itemControl, .themeSide, textarea.form-control {
    display: none !important;
  }
  .print-textarea {
    display: block;
  }
}`, "",{"version":3,"sources":["webpack://./src/stylesheets/print.scss"],"names":[],"mappings":"AAAA;EACI;IACI,WAAA;IACA,wBAAA;IACA,cAAA;EACN;EAEE;IACI,wBAAA;EAAN;EAGE;IACI,cAAA;EADN;AACF","sourcesContent":["@media print {\n    .stage-section:after{\n        content: \"\";\n        page-break-after:always;\n        display: block;\n    }\n\n    .print-none, .pageControl, .itemControl, .themeSide, textarea.form-control{\n        display: none !important;\n    }\n\n    .print-textarea{\n        display: block;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
