import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Form, Button } from 'react-bootstrap'
import { PieChat } from '../tools'
import Media from '../components/Media'

function Dashboard() {
  const colors = ['#3CFFDD', '#A167EC', '#012DFF']
  const values = [
    { symbol: 'File Image', value: 20 },
    { symbol: 'File 2', value: 40 },
    { symbol: 'File 3', value: 40 },
  ]

  return (
    <div className="content">
      <div className="dashboard-wrap d-flex flex-wrap">
        <div className="account-info col">
          <div className="shadow-block">
            <h2 className="dashboard-title">你的帳號</h2>
            <div className="account-info-list">
              <AcountLabel
                setting={{
                  label: '名稱',
                  name: '帳號',
                }}
              />
              <AcountLabel
                setting={{
                  label: '電子郵件位址',
                  name: 'polly@4point-inc.com',
                }}
              />
            </div>
          </div>
        </div>
        <div className="account-detail col">
          <div className="shadow-block">
            <h2 className="dashboard-title">Detail All Usage</h2>
            <div className="d-flex flex-wrap">
              <div className="pie-wrap">
                <PieChat setting={{ values, colors }} />
              </div>
              <div className="used-info">
                <strong>All File</strong>
                {values.map(({ symbol, value }, index) => (
                  <div
                    className="used-item d-flex flex-wrap"
                    key={`symbol${index}`}
                  >
                    <div
                      className="circle"
                      style={{ backgroundColor: colors[index] }}
                    >
                      &nbsp;
                    </div>
                    <div className="symbol-title">
                      {symbol} <span>{value}%</span>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Media />
    </div>
  )
}

function AcountLabel({ setting }) {
  const { label, name } = setting
  const [showTitleText, setShowTitleText] = useState(false)

  return (
    <div className="account-info-item">
      <strong>{label}</strong>
      <div className="account-input">
        {showTitleText ? (
          <Form.Control
            name="title"
            type="text"
            onChange={() => {}}
            onBlur={() => {}}
            onKeyDown={() => {}}
            style={{
              boxShadow: '0 0 0 0.25rem rgba(63, 117, 237, 0.25)',
              flex: '1',
            }}
            defaultValue={name}
          />
        ) : (
          <div className="text">{name}</div>
        )}
        <Button
          onClick={() => {
            setShowTitleText(!showTitleText)
          }}
        >
          編輯
        </Button>
      </div>
    </div>
  )
}

AcountLabel.propTypes = {
  setting: PropTypes.shape().isRequired,
}

export default Dashboard
