import { create } from 'zustand'

const useCustomerJourneyStore = create((set) => ({
  user_info: {},
  emotions: {},
  opportunities_and_painpoints: {},
  journeys: {},
  setField: (field, value) => set((state) => ({ ...state, [field]: value })),
}))

export default useCustomerJourneyStore
