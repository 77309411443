/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import * as allCurves from '@visx/curve'
import { AxisLeft, AxisBottom } from '@visx/axis'
import { LinePath } from '@visx/shape'
import { GridRows } from '@visx/grid'
import { Group } from '@visx/group'
import { scaleBand, scaleLinear, scalePoint } from '@visx/scale'
// import { Text } from '@visx/text'
import { Drag } from '@visx/drag'
import SvgEmoji from '../components/SvgEmoji'

const verticalMargin = 0
const getLetter = (d) => d.event
const getLetterFrequency = (d) => Number(d.emotion)

function tickComponent(props) {
  const { x, y, formattedValue } = props
  let emoji = 'no'
  let gap = 0

  if (formattedValue === '2.0') {
    emoji = 'smile'
  } else if (formattedValue === '0.0') {
    emoji = 'normal'
    gap = 15
  } else if (formattedValue === '−2.0') {
    emoji = 'bad'
    gap = 8
  }

  return (
    <g x={x} y={y}>
      {/* <Text y={y} verticalAnchor="start">
        {formattedValue}
      </Text> */}
      {emoji !== 'no' && <SvgEmoji value={emoji} y={y - gap} />}
    </g>
  )
}

function Emoji(props) {
  const { x, y, dy } = props
  const emoji = 'smile'

  return (
    <g>
      {/* <Text y={y} verticalAnchor="start">
        {formattedValue}
      </Text> */}
      {emoji !== 'no' && <SvgEmoji value={emoji} x={x - 12} y={y} dy={dy} />}
    </g>
  )
}

function BarChat({ setting }) {
  const {
    emotions,
    width = 1000,
    height = 498,
    boardStyle = 'horizon',
  } = setting
  const margin = { top: 15, right: 0, bottom: 0, left: 0 }
  // const percent = height / 6

  // bounds
  const xMax = width
  const yMax = height - verticalMargin

  const [data, setData] = useState([])

  useEffect(() => {
    setData([
      {
        emotion: parseFloat(emotions[0]),
        event: 1,
      },
      {
        emotion: parseFloat(emotions[1]),
        event: 3,
      },
      {
        emotion: parseFloat(emotions[2]),
        event: 5,
      },
      {
        emotion: parseFloat(emotions[3]),
        event: 7,
      },
      {
        emotion: parseFloat(emotions[4]),
        event: 9,
      },
    ])
  }, [emotions])

  // scales, memoize for performance
  const xScale = useMemo(
    () =>
      scaleBand({
        range: [0, xMax],
        round: true,
        domain: [1, 2, 3, 4, 5, 6, 7, 8, 9],
        padding: 0.8,
      }),
    [xMax]
  )

  const lineXScale = useMemo(
    () =>
      scalePoint({
        range: [0, xMax],
        round: true,
        domain: [1, 2, 3, 4, 5, 6, 7, 8, 9],
        padding: 0.86,
      }),
    [xMax]
  )

  const yScale = useMemo(
    () =>
      scaleLinear({
        range: [yMax, margin.top],
        round: true,
        domain: [-3, 2],
      }),
    [yMax]
  )

  const customScale = scaleLinear({
    domain: [-3, 2],
  })

  customScale.range([yMax, 0])

  const [draggingItems, setDraggingItems] = useState(
    data.map(({ event, emotion }, i) => ({
      id: i,
      x: event * 100,
      y: emotion,
      radius: 12,
      color: 'transparent',
    }))
  )

  useEffect(() => {
    setDraggingItems(
      data.map(({ event, emotion }) => {
        const x = xScale(event) + xScale.bandwidth() / 2
        const refined = parseFloat(emotion, 10)
        const y = height - (height / 5) * (refined + 3)

        return {
          event,
          id: event,
          x,
          y,
          radius: 12,
          color: 'transparent',
        }
      })
    )
  }, [data, width])

  const [isDragging, setIsDragging] = useState(false)
  const [isHover, setIsHover] = useState(false)
  /*
    y 軸 -3 ~ 2
    笑臉 2分
  */
  return width < 10 ? null : (
    <svg width="100%" height={height}>
      <GridRows
        scale={yScale}
        width={xMax}
        height={yMax}
        numTicks={5}
        stroke="#E2E4E7"
      />
      <AxisLeft
        hideAxisLine
        scale={customScale}
        tickValues={[-3, -2, -1, 0, 1, 2]}
        tickComponent={tickComponent}
      />
      <AxisBottom
        hideAxisLine
        // hideTicks
        scale={xScale}
        top={yMax - 8}
        stroke="#0E1216"
        numTicks={5}
        tickLabelProps={{
          fontSize: 0,
        }}
      />
      <Group top={verticalMargin / 2} width={width}>
        <LinePath
          curve={allCurves.curveLinear}
          data={data}
          x={(d) => lineXScale(getLetter(d))}
          y={(d) => yScale(getLetterFrequency(d)) ?? 0}
          stroke={boardStyle === 'horizon' ? '#0E1216' : '#3F75ED'}
          strokeWidth={1}
          strokeOpacity={1}
          strokeDasharray={boardStyle === 'horizon' ? 3 : 0}
          shapeRendering="geometricPrecision"
          markerMid="url(#marker-circle)"
          markerStart="url(#marker-line)"
          markerEnd="url(#marker-arrow)"
        />
      </Group>
      {boardStyle === 'horizon' && (
        <Group>
          {draggingItems.map((d) => (
            <Drag
              key={`drag-${d.id}`}
              width={width}
              height={height}
              x={d.x}
              y={d.y + 15}
              onDragStart={() => setIsDragging(d.id)}
              onDragMove={(e) => {
                if (!isDragging) return
                const result = draggingItems.map((item) =>
                  item.id === d.id ? { ...item, y: item.y + e.dy } : item
                )
                setData(
                  result.map((r) => ({
                    emotion: (height - r.y) / (height / 5) - 3,
                    event: r.event,
                  }))
                )
              }}
              onDragEnd={() => {
                // handleEmotionChange(
                //   data.sort((a, b) => a.event - b.event).map((item) => item.emotion)
                // )
                setIsDragging(false)
              }}
            >
              {({ dragStart, dragEnd, dragMove, x, y }) => (
                <React.Fragment key={`dot-${d.id}`}>
                  <circle
                    cx={x}
                    cy={y || 0}
                    r={15}
                    fill={
                      d.id === isDragging
                        ? '#FFD764'
                        : d.id === isHover
                        ? '#FFD764'
                        : '#FFD764'
                    }
                    fillOpacity={1}
                    stroke="transparent"
                    strokeWidth={2}
                    onMouseMove={dragMove}
                    onMouseUp={dragEnd}
                    onMouseDown={dragStart}
                    onMouseEnter={() => setIsHover(d.id)}
                    onMouseLeave={() => setIsHover('')}
                    onTouchStart={dragStart}
                    onTouchMove={dragMove}
                    onTouchEnd={dragEnd}
                    cursor={d.id === isDragging ? 'grabbing' : 'grab'}
                  />
                </React.Fragment>
              )}
            </Drag>
          ))}
        </Group>
      )}
    </svg>
  )
}

Emoji.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  dy: PropTypes.number,
}

BarChat.propTypes = {
  setting: PropTypes.shape().isRequired,
}

export default BarChat
